.siteHeader {position: fixed;float: left;width: 100%;z-index: 9000;height:100px;background: alpha($black,0.6);
  &__Logo {float: left;width: 12%;position: relative;z-index: 100;text-align: left;line-height: 100px;
    img {vertical-align: middle;max-height: 80px;}
  }
}


.js-SiteHeader {
    transition: background .7s ease-in-out;
    &--is-Fixed {position: fixed;left: 0px;width: 100%;top: 0px;background: $black;height: 100px;
    }
}