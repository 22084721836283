.productSingle {float: left;width: 100%;background: $white;
  &__Content {overflow: hidden;position: relative;padding-bottom: 100px;
    
  }
  &__Top {width: 100%;max-width: 800px;margin: auto;text-align: center;margin-top: 70px;
      h6 {font-size: 14px;text-transform: uppercase;font-weight: bold;color: $brand;margin-top: 0;margin-bottom: 0;letter-spacing: 1px;}
      h6.price {font-size: 20px;text-transform: uppercase;font-weight: bold;color: $black;margin-top: 0;letter-spacing: 1px;margin-bottom: 25px;}
      h4.date {font-size: 14px;font-weight: lighter;color: $brand;margin: 0;margin-bottom: 40px;}
      h2 {margin: 0;font-size: 38px;color: $brand;font-weight: lighter;line-height: 1.2;max-width: 800px;margin: auto;margin-bottom: 120px;position: relative;z-index: 1;
        &:before {content: '';position: absolute;bottom: -80px;left: 0;right: 0;background: #f0f0f0;height: 1px;}
      }
      a {text-decoration: none;}
  }
  &__Cover {float: left;width: 70%;margin-left: 15%;height: 450px;background-repeat: no-repeat;background-size: auto 100%;background-position: center;margin-bottom: 50px;}
  &__Inner {max-width: 665px;margin: auto;position: relative;
    h1 {font-size: 48px;line-height: 1.2;font-weight: lighter;}
    h2 {font-size: 26px;line-height: 1.4;font-weight: lighter;}
    h3 {font-size: 22px;font-weight: bold;}
    h4, h5, h6 {font-size: 18px; line-height: 1.6;color: $black;}
    .wp-caption {width: auto !important;
      p {font-size: 12px;font-weight: lighter;color: $brand;}
    }
    p {font-size: 18px; line-height: 1.6;color: $black;}
    li {font-size: 18px; line-height: 1.6;color: $black;}
    span {font-size: 18px; line-height: 1.6;color: $black;}
    a {font-size: 18px; line-height: 1.6;color: $brand;text-decoration: none;}
    img {min-width: 765px;margin-left: -100px;}
    mark {background: #e7f5fe;}
    blockquote {background: #e7f5fe; font-weight: lighter; font-size: 16px; padding: 25px;color: #004e99;width: 100%;float: left;margin-left: 0;margin-right: 0;line-height: 1.8;margin-bottom: 60px;
      p {margin: 0;line-height: 1.8;color: #004e99;}
    }
    &--intro {margin-top: 60px;margin-bottom: 60px;
      p {font-size: 26px;line-height: 1.4;font-weight: lighter;}
      a {color: $brand;border-bottom: 1px dotted $brand;text-decoration: none;font-size: 26px;line-height: 1.4;font-weight: lighter;
        &:hover {border-bottom: 1px solid $brand;}
      }
    }
    &--gallery {
      float: left;width: 100%;padding: 50px 0;
      a {float: left;width: 23%;margin-left: 1%;margin-right: 1%;margin-bottom: 1%;height: 150px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
    }
    iframe {width: 100%;height: 400px;}
    input {width: 100%;border: 1px solid #dedede;padding: 0 10px;color: $black;font-weight: lighter;line-height: 46px;font-size: 14px;}
    textarea {width: 100%;max-width: 100%;min-width: 100%;border: 1px solid #dedede;padding: 0 10px; color: $black;font-weight: lighter;font-size: 14px;}
    input[type="submit"] {@include moreButton(#f0f0f0, #f0f0f0);color: $black;
      &:hover {background: $brand;color: $black;}
    }
    table {width: 100% !important;display: table;
      tr {
        &:nth-of-type(2n-1) {background: $gray;}
      }
      td {display: table-cell;width: auto !important;height: 40px !important;line-height: 40px !important;background-color: transparent !important;padding: 0 10px;
      }
      td:empty {
        display: none;
      }
    }
  }
}
.relatedProducts {padding-bottom: 150px;float: left;width: 100%;margin-top: 100px;
  &__Title {margin: 0;font-size: 36px;color: $black;font-weight: lighter;line-height: 1.2;max-width: 800px;margin: auto;margin-bottom: 90px;text-align: center;}
 &__Subtitle {margin: 0;font-size: 12px;letter-spacing: 2px;text-align: center;color: $brand;margin: 0;margin-bottom: 20px;text-transform: uppercase;font-weight: normal;}
  &__Inner {max-width: 600px;margin: auto;}
  &__Box {border-radius: 6px;padding: 50px 0px;background: $white;position: relative;z-index: 1;float: left;width: 48%;margin-left: 1%;margin-right: 1%;
        h2 {font-weight: lighter;margin: 0;color: $black;font-size: 20px;line-height: 1.3;transition:300ms;text-align: center;top: 50%;transform:translateY(-50%);position: relative;
          &:hover {color: #004e99;}
        }
        h4 {text-transform: uppercase;font-size: 12px;line-height: 1.1;color: $brand;font-weight: bold;margin: 0;margin-bottom: 10px;text-align: center;}
        p {font-size: 18px;color: $black;font-weight: lighter;margin: 0;line-height: 1.6;}
        &:before {left: 0px;right: 0;bottom: 0;height: 1px;background: #f0f0f0;position: absolute;content: '';z-index: -1;}
        a {text-decoration: none;transition:300ms;}
        &--titleLink {height: 100px;float: left;width: 100%;margin-bottom: 20px;}
        &--image {float: left;width: 100%;height: 200px;background-repeat: no-repeat;background-size: auto 100%;background-position: center;}
        &--more {@include moreButton($brand, $brand);color: $white;margin-top: 50px;float: none;margin: auto;
          &:hover {color: $brand;}
        }
  }
}
.categoryProducts {padding-bottom: 150px;float: left;width: 100%;margin-top: 100px;
  &__Title {margin: 0;font-size: 36px;color: $black;font-weight: lighter;line-height: 1.2;max-width: 800px;margin: auto;margin-bottom: 90px;text-align: center;}
 &__Subtitle {margin: 0;font-size: 12px;letter-spacing: 2px;text-align: center;color: $brand;margin: 0;margin-bottom: 20px;text-transform: uppercase;font-weight: normal;}
  &__Inner {max-width: 600px;margin: auto;}
  &__Box {border-radius: 6px;padding: 50px 0px;background: $white;position: relative;z-index: 1;float: left;width: 31%;margin-left: 1%;margin-right: 1%;
        h2 {font-weight: lighter;margin: 0;color: $black;font-size: 28px;line-height: 1.3;margin-bottom: 20px;transition:300ms;text-align: center;
          &:hover {color: #004e99;}
        }
        h4 {text-transform: uppercase;font-size: 12px;line-height: 1.1;color: $brand;font-weight: bold;margin: 0;margin-bottom: 10px;text-align: center;}
        p {font-size: 18px;color: $black;font-weight: lighter;margin: 0;line-height: 1.6;}
        &:before {left: 0px;right: 0;bottom: 0;height: 1px;background: #f0f0f0;position: absolute;content: '';z-index: -1;}
        a {text-decoration: none;transition:300ms;}
        
        &--image {float: left;width: 100%;height: 200px;background-repeat: no-repeat;background-size: auto 100%;background-position: center;}
        &--more {@include moreButton($brand, $brand);color: $white;margin-top: 50px;transform:translateX(-50%);left: 50%;
          &:hover {color: $brand;}
        }
  }
}

.machineCategory {float: left;width: 100%;background: $white;
  &__Content {overflow: hidden;position: relative;padding-bottom: 100px;
    
  }
  &__Top {width: 100%;max-width: 800px;margin: auto;text-align: center;margin-top: 70px;
      h2 {margin: 0;font-size: 48px;color: $brand;font-weight: lighter;line-height: 1.2;max-width: 800px;margin: auto;margin-bottom: 10px;position: relative;z-index: 1;
        &:before {content: '';position: absolute;bottom: -50px;left: 0;right: 0;background: #f0f0f0;height: 1px;}
        &:hover {color: $black;cursor: pointer;}
      }
      h5 {margin: 0;color: $brand;font-size: 16px;display: inline-block;margin-bottom: 70px;font-weight: lighter;line-height: 1.3;text-transform: uppercase;margin: 0 5px;margin-bottom: 90px;
        &:hover {color: $black;cursor: pointer;}
      }
      .activeProducts {color: $black;}
      a {text-decoration: none;}
  }
  &__Cover {float: left;width: 100%;height: 450px;background-repeat: no-repeat;background-size: cover;background-position: center;margin-bottom: 50px;}
  

  &__Box {margin-left: 2%;margin-right: 2%;float: left;width: 29%;margin-bottom: 40px;min-height: 360px;
        a {text-decoration: none;}
        h4 {margin: 0;min-height: 50px;color: $black;}
        h5 {margin: 0;font-weight: 300;font-size: 14px;margin-bottom: 10px;color: $black;}
        p {font-size: 14px;color: $black;}
        &--img {float: left;width: 100%;height: 170px;background-size: cover;background-repeat: no-repeat;background-position: center center;margin-bottom: 10px;}
    }
}
.machineCategory__Inner--box {float: left;width: 46%;position: relative;z-index: 1;margin-left: 2%;margin-right: 2%;
  // &:before {content: '';position: absolute;top: 0;bottom: 0;right: -10px;background: $gray;width: 1px;z-index: -1;}
  p {font-size: 14px;}
}
.full-width {width: 96% !important;}
.lb-number {display: none !important;}
.productFormOutbox {float: left;width: 100%;position: relative;}
.productForm {position: absolute;background: $white;width: 665px;z-index: -10000000000;opacity: 0;top: -350px;padding: 50px;padding-bottom: 0;transition:opacity 500ms;
  label {font-size: 13px;font-weight: lighter;}
  input {margin-bottom: 10px;height: 30px;}
  input[type="submit"] {height: 50px;}
  textarea {margin-bottom: 10px;}
  &__Call {@include moreButton($brand, transparent);color: $brand !important;font-size: 14px;font-weight: lighter;padding-top: 10px;padding-bottom: 10px;margin-top: 50px;
    &:before {background: $brand;}
    &:hover {color: $white !important;cursor: pointer;}
  }
  &__Close {position: absolute;float: right;right: 50px;transition:500ms;top: 30px;
    &:hover {color: $brand;transform:rotate(180deg) scale(1.3);cursor: pointer;}
  }
}
.activeForm {opacity: 1;z-index: 10000000000;}
.activeBody {position: relative;z-index: 1;float: left;width: 100%;transition:opacity 500ms;
  &:before {content: '';top: 0;bottom: 0;right: 0;left: 0;background: $black;opacity: 0.5;z-index: 1000000000;position: absolute;}
}
.productBox{width: 24%;margin-bottom: 1%;margin-right: 0.5%;margin-left: 0.5%;text-align: center;line-height: 180px;float: left;
            a {border: 0;float: left;width: 100%;
              &:hover {border: 0;}
                h2 {font-size: 18px;
                  &:hover {color: $brand2;}
                }
            }
            &__Box {float: left;width: 100%;height: 180px;background: $white;transition:300ms;border: 1px solid $black !important;position: relative;text-align: center;margin: 0;
                img {width: 100%;vertical-align: middle;line-height: 300px;position: absolute;top: 50%;transform:translateY(-50%);left: 0;margin-left: 0 !important;min-width: auto !important;}
                img.whiteIcon {opacity: 0;}
                img.normalIcon {opacity: 1;}
                &:hover {border: 1px solid $brand2 !important;background: $brand2;
                    img.whiteIcon {opacity: 1;}
                    img.normalIcon {opacity: 0;}
                }
            }
        }
        .categoryBox{width: 48%;margin-bottom: 1%;margin-right: 1%;margin-left: 1%;text-align: center;line-height: 180px;float: left;
            // &:first-of-type {margin-left: 18%;}
            // &:last-of-type {margin-right: 18%;}
            a {border: 0;float: left;width: 100%;
              &:hover {border: 0;}
                h2 {font-size: 18px;
                  &:hover {color: $brand2;}
                }
            }
            &__Box {float: left;width: 100%;height: 300px;background: $white;transition:300ms;position: relative;text-align: center;margin: 0;overflow: hidden;
                img {width: auto;vertical-align: middle;line-height: 300px;position: absolute;top: 50%;transform:translateY(-50%) translateX(-50%) scale(0.8);left: 50%;margin-left: 0 !important;min-width: auto !important;transition:300ms;height: 100%;max-width: initial !important;}
                &:hover {
                    img.normalIcon {top: 50%;transform:translateY(-50%) translateX(-50%) scale(0.9);}
                }
            }
        }
        .defineHeight {min-height: 530px;}