@charset "UTF-8";
@import url(../../assets/bower_components/normalize.css/normalize.css);
/* Slider */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&subset=cyrillic-ext");
@import url("https://fonts.googleapis.com/css?family=Palanquin:100,200,300,400,500,600,700&subset=latin-ext");
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  /*
    img {
        display: block;
    }
    */
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px\9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

body {
  font: 16px/1.5 "Open Sans", sans-serif;
  height: 100%;
  background-color: #e2e2e2; }

#__bs_notify__ {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -125px !important;
  width: 250px;
  height: 50px;
  border-radius: 0 !important;
  opacity: 0.5; }

.center {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .center::after {
    clear: both;
    content: "";
    display: table; }

.clearfix::after {
  clear: both;
  content: "";
  display: table; }

::selection {
  background: #cccccc;
  text-shadow: none; }

.t-Title, .t-Title--with-MarginBottom {
  text-transform: uppercase;
  font-size: 2.25em;
  line-height: 44px;
  letter-spacing: 3.5px;
  margin-top: 100px;
  font-weight: 300;
  margin-bottom: 0;
  text-align: center; }
  .t-Title--with-MarginBottom {
    margin-bottom: 70px; }

.t-input-Select {
  background: #e2e2e2;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  transition: 200ms;
  backface-visibility: hidden;
  margin-right: 10px; }
  .t-input-Select option {
    background: transparent; }
  .t-input-Select:hover {
    background: #d5d5d5; }
    .t-input-Select:hover:after {
      background-color: #d5d5d5; }
  .t-input-Select:active {
    transform: scale(0.97);
    transition: 60ms; }
  .t-input-Select:after {
    display: block;
    content: "";
    position: absolute;
    width: 40px;
    height: 100%;
    line-height: 43px;
    font-size: 0.75em;
    color: #999999;
    top: 0;
    right: 0;
    text-align: center;
    background: no-repeat center center;
    background-color: #e2e2e2;
    pointer-events: none;
    transition: 200ms; }
  .t-input-Select select {
    border: 0;
    -webkit-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    padding: 9px 10px 8px;
    outline: none;
    cursor: pointer;
    color: #4d4d4d;
    display: block;
    background: #FFF;
    background: transparent;
    padding-right: 40px;
    display: block; }
  .t-input-Select select[disabled] {
    color: rgba(0, 0, 0, 0.3); }

.t-MoreButton {
  border: 1px #FFF solid;
  display: inline-block;
  padding: 0 30px;
  line-height: 46px;
  text-decoration: none;
  font-size: 14px;
  color: #FFF;
  position: relative;
  overflow: hidden;
  z-index: 1;
  vertical-align: bottom;
  background: transparent;
  transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
  letter-spacing: 1px;
  font-weight: bold; }
  .t-MoreButton:hover {
    color: #3f3222; }
    .t-MoreButton:hover:before {
      opacity: 1;
      right: -100px;
      left: -100px; }
  .t-MoreButton:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    top: -10px;
    bottom: -10px;
    transform: skew(40deg);
    background: #FFF;
    left: 100%;
    right: 110%;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }

img {
  max-width: 100%;
  height: auto; }

a {
  cursor: pointer; }

img.alignright {
  float: right;
  margin: 0 0 1em 1em; }

img.alignleft {
  float: left;
  margin: 0 1em 1em 0; }

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.alignright {
  float: right; }

.alignleft {
  float: left; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.navigation li.active a {
  color: #ebb33a; }

.bluetable table {
  text-align: left;
  border: 1px solid #dbdbdb;
  -webkit-box-shadow: 1px 1px 3px 0 rgba(180, 180, 180, 0.2);
  -moz-box-shadow: 1px 1px 3px 0 rgba(180, 180, 180, 0.2);
  box-shadow: 1px 1px 3px 0 rgba(180, 180, 180, 0.2);
  margin-left: -80px;
  width: 760px; }

.bluetable th {
  padding: 10px 10px;
  background-image: linear-gradient(bottom, #f7f7f7 0, #fff 100%);
  background-image: -o-linear-gradient(bottom, #f7f7f7 0, #fff 100%);
  background-image: -moz-linear-gradient(bottom, #f7f7f7 0, #fff 100%);
  background-image: -webkit-linear-gradient(bottom, #f7f7f7 0, #fff 100%);
  background-image: -ms-linear-gradient(bottom, #f7f7f7 0, #fff 100%);
  background-color: #f9f9f9;
  border: 1px solid #e2e2e2; }

.bluetable td {
  padding: 10px 10px;
  vertical-align: middle;
  border: 1px solid #e2e2e2; }

.pp_right {
  background: transparent !important; }

.pp_left {
  background: transparent !important; }

.pp_bottom {
  background: transparent !important;
  display: none !important; }

.pp_top {
  background: transparent !important;
  display: none !important; }

.pp_content {
  background: transparent !important; }

/*custom hide*/
.location-nr-27, .nr-prekogranicni-rezervat-biosfere-mura-drava-dunav, .hideElement {
  display: none !important; }

.customBlock {
  float: left;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 50px;
  background: #f2f2f2; }
  .customBlock p {
    color: #3f3222; }
  .customBlock a {
    color: #5ec26a; }
    .customBlock a:hover {
      color: #3f3222; }

.greenColorBar {
  height: 80px !important;
  line-height: 80px !important;
  margin: 0 !important;
  padding: 0 !important; }
  .greenColorBar__Wrap {
    float: left;
    width: 100%;
    background: #5ec26a; }
  .greenColorBar li {
    color: #FFF !important;
    position: relative;
    z-index: 1; }
    .greenColorBar li:after {
      display: none !important; }
    .greenColorBar li:before {
      left: -2px;
      right: -2px;
      background: #000;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;
      transition: 300ms; }
    .greenColorBar li:hover {
      color: #FFF !important; }
      .greenColorBar li:hover:before {
        opacity: 0.3; }
  .greenColorBar .locationCatActive:before {
    opacity: 0.3 !important; }

.homeSlider {
  margin-bottom: 0 !important;
  float: left;
  width: 100%;
  overflow: hidden; }
  .homeSlider__Slide {
    height: 100vh !important;
    float: left;
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    z-index: 1;
    background-attachment: fixed !important; }
    .homeSlider__Slide:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      opacity: 0.3; }
    .homeSlider__Slide .center {
      height: 100vh; }
  .homeSlider h2 {
    font-size: 50px;
    color: #FFF;
    margin: 0px;
    position: relative;
    z-index: 1;
    font-weight: bold;
    text-transform: uppercase; }
  .homeSlider h4 {
    font-size: 34px;
    font-weight: 300;
    color: #FFF;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: "Palanquin", sans-serif;
    font-weight: 100; }
  .homeSlider img {
    max-width: 200px;
    margin-bottom: 40px; }
  .homeSlider__More {
    background: #ebb33a;
    color: #3f3222;
    text-transform: uppercase;
    padding-left: 40px;
    padding-right: 40px;
    height: 40px;
    line-height: 40px;
    font-weight: normal;
    border: 0;
    display: inline-block;
    text-decoration: none;
    transition: 300ms; }
    .homeSlider__More:hover {
      color: #FFF;
      background: #3f3222; }
  .homeSlider__Inner {
    position: absolute; }
  .homeSlider .topLeft {
    top: 150px;
    left: 50px;
    text-align: left; }
  .homeSlider .topCenter {
    top: 150px;
    left: 0;
    right: 0;
    text-align: center; }
  .homeSlider .topRight {
    top: 150px;
    right: 50px;
    text-align: right; }
  .homeSlider .centerLeft {
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    text-align: left; }
  .homeSlider .centerCenter {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center; }
  .homeSlider .centerRight {
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    text-align: right; }
  .homeSlider .bottomLeft {
    bottom: 150px;
    left: 50px;
    text-align: left; }
  .homeSlider .bottomCenter {
    bottom: 150px;
    left: 0;
    right: 0;
    text-align: center; }
  .homeSlider .bottomRight {
    bottom: 150px;
    right: 50px;
    text-align: right; }
  .homeSlider__Show {
    margin-top: 200px;
    color: #FFF;
    float: left;
    width: 100%; }
    .homeSlider__Show i {
      transform: rotate(90deg); }
  .homeSlider .slick-dots {
    bottom: 50px;
    height: 15px;
    line-height: 15px;
    margin: 0; }
    .homeSlider .slick-dots li {
      width: 15px;
      height: 15px;
      margin: 0 2.5px; }
      .homeSlider .slick-dots li button {
        padding: 0 !important;
        width: 15px;
        height: 15px;
        line-height: 15px;
        position: relative;
        z-index: 1; }
        .homeSlider .slick-dots li button:before {
          font-size: 35px;
          color: #FFF;
          opacity: 0.5;
          width: 15px;
          border: 1px solid #FFF;
          height: 15px;
          line-height: 15px;
          padding-left: 1px;
          border-radius: 100%;
          content: ''; }
        .homeSlider .slick-dots li button:after {
          opacity: 0.5;
          border-radius: 100%;
          content: '';
          background: #FFF;
          z-index: -1;
          position: absolute;
          left: 2px;
          top: 2px;
          right: 2px;
          bottom: 2px; }
    .homeSlider .slick-dots li.slick-active button:before {
      color: #FFF;
      opacity: 1;
      font-size: 35px; }
  .homeSlider .arrowPrev, .homeSlider .arrowNext {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    height: 100%;
    z-index: 10000; }
  .homeSlider .arrowPrev {
    height: 100vh;
    line-height: 100vh;
    opacity: 1;
    transition: 200ms;
    color: #FFF;
    font-size: 40px;
    padding-left: 15px; }
    .homeSlider .arrowPrev:after {
      width: 70px;
      height: 100px;
      bottom: 45%;
      top: 45%;
      left: 0px;
      position: absolute;
      content: '';
      background: rgba(255, 255, 255, 0.3);
      text-align: center;
      transition: 200ms; }
    .homeSlider .arrowPrev:hover {
      transition: 200ms; }
      .homeSlider .arrowPrev:hover:before {
        opacity: 0.6;
        transition: 200ms; }
    .homeSlider .arrowPrev:before {
      line-height: 100vh;
      opacity: 1;
      font-size: 60px !important;
      z-index: 10000;
      color: #FFF;
      position: absolute;
      left: 20px;
      transition: 200ms; }
  .homeSlider .arrowNext {
    height: 100vh;
    line-height: 100vh;
    transition: 200ms;
    color: #FFF;
    font-size: 40px;
    opacity: 1;
    transition: 200ms;
    right: 0;
    text-align: right;
    padding-right: 15px; }
    .homeSlider .arrowNext:after {
      width: 70px;
      height: 100px;
      bottom: 45%;
      top: 45%;
      right: 0px;
      position: absolute;
      content: '';
      background: rgba(255, 255, 255, 0.3);
      text-align: center;
      transition: 200ms; }
    .homeSlider .arrowNext:hover {
      transition: 200ms; }
      .homeSlider .arrowNext:hover:before {
        opacity: 0.6;
        transition: 200ms; }
    .homeSlider .arrowNext:before {
      line-height: 100vh;
      opacity: 1;
      font-size: 60px !important;
      z-index: 10000;
      color: #FFF;
      position: absolute;
      right: 20px;
      transition: 200ms; }

.threewidgets {
  float: left;
  width: 100%;
  background: #3f3222; }
  .threewidgets__Box {
    float: left;
    width: 32.33%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    text-align: center;
    padding: 50px 0; }
    .threewidgets__Box i {
      color: #FFF;
      font-size: 30px;
      border: 1px solid #FFF;
      border-radius: 100%;
      height: 60px;
      width: 60px;
      text-align: center;
      line-height: 57px; }
    .threewidgets__Box h3 {
      color: #FFF; }
    .threewidgets__Box p {
      color: #FFF;
      padding-left: 20%;
      margin-right: 20%; }
    .threewidgets__Box a {
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      color: #FFF;
      z-index: 1;
      position: relative; }
      .threewidgets__Box a:before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        height: 1px;
        background: #FFF;
        z-index: -1;
        opacity: 0;
        transition: 300ms; }
      .threewidgets__Box a:hover:before {
        bottom: -2px;
        opacity: 1; }
    .threewidgets__Box:nth-of-type(2) {
      background: rgba(255, 255, 255, 0.1); }

.sixbox {
  float: left;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
  position: relative;
  z-index: 1;
  background-attachment: fixed; }
  .sixbox:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #FFF;
    opacity: 0.5; }
  .sixbox__Box {
    float: left;
    width: 24%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    text-align: center;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden; }
    .sixbox__Box h3 {
      color: #3f3222;
      position: absolute;
      top: 134px;
      left: 0;
      right: 0;
      padding: 10px 0;
      z-index: 1;
      font-weight: normal;
      transition: 300ms; }
      .sixbox__Box h3:before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #FFF;
        z-index: -1;
        opacity: 0.7; }
    .sixbox__Box p {
      color: #3f3222;
      padding-left: 20%;
      margin-right: 20%;
      position: absolute;
      top: 250px;
      transition: 500ms;
      z-index: 1; }
    .sixbox__Box a {
      text-decoration: none; }
    .sixbox__Box:nth-of-type(1) {
      clear: both; }
    .sixbox__Box:nth-of-type(2) {
      clear: both; }
    .sixbox__Box:nth-of-type(3) {
      height: 420px;
      margin-top: -220px; }
      .sixbox__Box:nth-of-type(3) h3 {
        top: 354px; }
      .sixbox__Box:nth-of-type(3) p {
        top: 500px; }
      .sixbox__Box:nth-of-type(3):hover h3 {
        top: -18px;
        bottom: -19px;
        padding-top: 80px; }
      .sixbox__Box:nth-of-type(3):hover p {
        top: 100px; }
    .sixbox__Box:nth-of-type(4) {
      height: 420px;
      margin-top: -220px; }
      .sixbox__Box:nth-of-type(4) h3 {
        top: 354px; }
      .sixbox__Box:nth-of-type(4) p {
        top: 500px; }
      .sixbox__Box:nth-of-type(4):hover h3 {
        top: -18px;
        bottom: -19px;
        padding-top: 80px; }
      .sixbox__Box:nth-of-type(4):hover p {
        top: 100px; }
    .sixbox__Box:nth-of-type(5) {
      margin-top: -220px; }
    .sixbox__Box:hover h3 {
      top: -18px;
      bottom: -19px;
      padding-top: 40px; }
    .sixbox__Box:hover p {
      top: 60px; }

.about {
  float: left;
  width: 100%;
  text-align: center;
  background: #FFF;
  padding: 60px 0; }
  .about h2 {
    font-size: 40px;
    color: #000;
    margin: 0px;
    position: relative;
    z-index: 1;
    font-weight: bold;
    text-transform: uppercase; }
  .about p {
    font-weight: 300;
    color: #3f3222; }
  .about img {
    max-width: 300px;
    margin-bottom: 40px;
    opacity: 0.7; }
  .about .center {
    max-width: 740px; }

.numbers {
  float: left;
  width: 100%;
  padding: 0; }
  .numbers h3 {
    display: none; }
  .numbers .statistics-inner {
    padding: 0 !important; }
  .numbers .stat .fa {
    display: none !important; }

.homepageMenu {
  float: left;
  width: 100%;
  background: #5ec26a;
  text-align: center; }
  .homepageMenu ul {
    padding: 0;
    margin: 0; }
    .homepageMenu ul li {
      list-style: none;
      display: inline-block;
      padding: 30px 0; }
      .homepageMenu ul li a {
        text-decoration: none;
        color: #FFF;
        text-transform: uppercase;
        transition: 300ms;
        padding: 30px 10px; }
        .homepageMenu ul li a:hover {
          background: #3E7F46; }

.didyouknow {
  float: left;
  width: 100%;
  padding: 60px 0; }
  .didyouknow h2 {
    text-align: center;
    font-weight: lighter;
    font-size: 60px;
    margin: 0; }
  .didyouknow .arrowPrev, .didyouknow .arrowNext {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    height: 100%;
    z-index: 10000; }
  .didyouknow .arrowPrev {
    height: 300px;
    line-height: 300px;
    opacity: 1;
    transition: 200ms;
    color: #FFF;
    font-size: 40px;
    padding-left: 15px; }
    .didyouknow .arrowPrev:after {
      width: 70px;
      height: 100px;
      bottom: 45%;
      top: 45%;
      left: 0px;
      position: absolute;
      content: '';
      background: transparent;
      text-align: center;
      transition: 200ms; }
    .didyouknow .arrowPrev:hover {
      transition: 200ms; }
      .didyouknow .arrowPrev:hover:before {
        opacity: 0.6;
        transition: 200ms; }
    .didyouknow .arrowPrev:before {
      line-height: 300px;
      opacity: 1;
      font-size: 60px !important;
      z-index: 10000;
      color: #5ec26a;
      position: absolute;
      left: -120px;
      transition: 200ms; }
  .didyouknow .arrowNext {
    height: 300px;
    line-height: 300px;
    transition: 200ms;
    color: #FFF;
    font-size: 40px;
    opacity: 1;
    transition: 200ms;
    right: 0;
    text-align: right;
    padding-right: 15px; }
    .didyouknow .arrowNext:after {
      width: 70px;
      height: 100px;
      bottom: 45%;
      top: 45%;
      right: 0px;
      position: absolute;
      content: '';
      background: transparent;
      text-align: center;
      transition: 200ms; }
    .didyouknow .arrowNext:hover {
      transition: 200ms; }
      .didyouknow .arrowNext:hover:before {
        opacity: 0.6;
        transition: 200ms; }
    .didyouknow .arrowNext:before {
      line-height: 300px;
      opacity: 1;
      font-size: 60px !important;
      z-index: 10000;
      color: #5ec26a;
      position: absolute;
      right: -120px;
      transition: 200ms; }
  .didyouknow__Wrap {
    height: 300px;
    margin: auto;
    width: 100%;
    max-width: 860px; }
  .didyouknow__Box {
    float: left;
    width: 100%;
    position: relative;
    height: 300px; }
    .didyouknow__Box a {
      text-decoration: none; }
    .didyouknow__Box--img {
      float: left;
      width: 30%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
    .didyouknow__Box--content {
      width: 65%;
      margin-left: 5%;
      float: left;
      position: relative;
      top: 40%;
      transform: translateY(-50%); }
      .didyouknow__Box--content h4 {
        font-size: 28px;
        color: #5ec26a;
        font-weight: lighter;
        margin: 0; }
        .didyouknow__Box--content h4 i {
          font-size: 20px; }
      .didyouknow__Box--content p {
        color: #3f3222; }
      .didyouknow__Box--content--more {
        background: #ebb33a;
        color: #3f3222;
        text-transform: uppercase;
        padding-left: 40px;
        padding-right: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        border: 0;
        display: inline-block;
        text-decoration: none;
        transition: 300ms; }
        .didyouknow__Box--content--more:hover {
          color: #FFF;
          background: #3f3222; }

.video {
  float: left;
  width: 100%;
  height: 600px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  background-attachment: fixed; }
  .video:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.2;
    z-index: -1; }
  .video__Cnt {
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .video h2 {
    font-size: 60px;
    color: #FFF;
    margin-bottom: 0; }
  .video h4 {
    font-size: 48px;
    color: #FFF;
    margin-top: 0;
    font-family: "Palanquin", sans-serif;
    font-weight: 100; }
  .video a {
    text-decoration: none;
    color: #FFF; }
    .video a i {
      display: block;
      height: 70px;
      width: 70px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 100%;
      margin: auto;
      font-size: 30px;
      line-height: 70px;
      text-align: center;
      padding-left: 5px;
      margin-bottom: 15px; }

.latestNews {
  float: left;
  width: 100%;
  padding: 60px 0;
  background: #FFF; }
  .latestNews h2 {
    text-align: center;
    font-weight: lighter;
    font-size: 60px;
    margin: 0; }
  .latestNews h4 {
    text-align: center;
    font-weight: lighter;
    font-size: 30px;
    margin: 0;
    margin-bottom: 60px; }
  .latestNews__Wrap {
    width: 100%;
    max-width: 860px;
    margin: auto; }
  .latestNews__Box {
    float: left;
    width: 32.33%;
    position: relative;
    min-height: 300px;
    margin-left: 0.5%;
    margin-right: 0.5%;
    text-align: center;
    border: 1px solid #ededed;
    transition: 300ms; }
    .latestNews__Box a {
      text-decoration: none; }
    .latestNews__Box--img {
      float: left;
      width: 100%;
      height: 150px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative; }
    .latestNews__Box--author {
      float: left;
      width: 50%;
      height: 30px;
      background: #5ec26a;
      line-height: 30px;
      margin-bottom: 30px; }
      .latestNews__Box--author span {
        color: #FFF;
        font-size: 14px; }
    .latestNews__Box--date {
      float: left;
      width: 50%;
      height: 30px;
      background: #5ec26a;
      position: relative;
      z-index: 1;
      line-height: 30px;
      margin-bottom: 30px; }
      .latestNews__Box--date span {
        color: #FFF;
        font-size: 14px; }
      .latestNews__Box--date:before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        opacity: 0.2;
        z-index: -1; }
    .latestNews__Box--content {
      width: 80%;
      float: left;
      position: relative;
      margin-left: 10%;
      margin-right: 10%; }
      .latestNews__Box--content h4 {
        font-size: 18px;
        color: #3f3222;
        font-weight: lighter;
        margin: 0; }
      .latestNews__Box--content p {
        color: #3f3222;
        font-size: 14px; }
    .latestNews__Box:hover {
      border-color: #5ec26a; }

.parallax {
  background-position: 0 0; }

.addPosition {
  position: relative; }

.customLink {
  position: absolute;
  top: 40px;
  right: 0px;
  font-size: 14px;
  color: #3f3222; }
  .customLink a {
    color: #3f3222; }

.leftLink {
  right: auto;
  left: 0; }

@media screen and (max-width: 800px) {
  .customLink {
    top: -20px;
    right: auto;
    left: 50%;
    transform: translateX(-50%); }
  .leftLink {
    top: -20px;
    right: auto;
    left: 50%;
    transform: translateX(-50%); } }

.blogPage {
  padding-bottom: 250px;
  float: left;
  width: 100%;
  background: #FFF; }
  .blogPage__Top {
    width: 100%;
    max-width: 800px;
    margin: auto;
    padding-top: 200px;
    text-align: center; }
    .blogPage__Top h2 {
      margin: 0;
      font-size: 48px;
      color: #3f3222;
      font-weight: lighter;
      line-height: 1.2;
      max-width: 800px;
      margin: auto;
      margin-bottom: 30px; }
      .blogPage__Top h2 a {
        text-decoration: none;
        color: #3f3222; }
    .blogPage__Top h3 {
      margin: 0;
      color: #3f3222;
      font-size: 16px;
      max-width: 400px;
      margin: auto;
      margin-bottom: 70px;
      font-weight: lighter;
      line-height: 1.3; }
    .blogPage__Top h4 {
      margin: 0;
      color: #3f3222;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 2px;
      max-width: 500px;
      margin: auto;
      margin-bottom: 100px;
      font-weight: lighter; }
    .blogPage__Top h5 {
      margin: 0;
      color: #3f3222;
      font-size: 16px;
      display: inline-block;
      margin-bottom: 70px;
      font-weight: lighter;
      line-height: 1.3;
      text-transform: uppercase;
      margin: 0 5px; }
      .blogPage__Top h5:hover {
        color: #000;
        cursor: pointer; }
    .blogPage__Top .activeProducts {
      color: #000; }
    .blogPage__Top .smallCategory {
      text-decoration: none;
      margin: 0 5px; }
  .blogPage__Bottom {
    width: 100%;
    max-width: 800px;
    margin: auto;
    padding-top: 130px;
    text-align: center; }
    .blogPage__Bottom h2 {
      margin: 0;
      font-size: 36px;
      color: #FFF;
      font-weight: lighter;
      line-height: 1.2;
      max-width: 800px;
      margin: auto;
      margin-bottom: 10px; }
    .blogPage__Bottom h4 {
      margin: 0;
      color: #3f3222;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 6px;
      max-width: 400px;
      margin: auto;
      margin-bottom: 20px;
      font-weight: lighter; }
    .blogPage__Bottom p {
      font-size: 18px;
      line-height: 1.3;
      text-align: center;
      color: #FFF;
      font-weight: lighter;
      margin: 0;
      margin-bottom: 50px; }
  .blogPage__More {
    border: 1px #3f3222 solid;
    display: inline-block;
    padding: 0 30px;
    line-height: 46px;
    text-decoration: none;
    font-size: 14px;
    color: #3f3222;
    position: relative;
    overflow: hidden;
    z-index: 1;
    vertical-align: bottom;
    background: #3f3222;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
    letter-spacing: 1px;
    font-weight: bold;
    color: #FFF;
    border-radius: 6px; }
    .blogPage__More:hover {
      color: #3f3222; }
      .blogPage__More:hover:before {
        opacity: 1;
        right: -100px;
        left: -100px; }
    .blogPage__More:before {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      top: -10px;
      bottom: -10px;
      transform: skew(40deg);
      background: #FFF;
      left: 100%;
      right: 110%;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
    .blogPage__More:hover {
      background: #07b661;
      color: #FFF;
      border-color: #07b661; }
  .blogPage__Content {
    background: #FFF;
    padding: 100px;
    padding-top: 0; }
  .blogPage__Inner {
    max-width: 600px;
    margin: auto; }
  .blogPage__Box {
    padding: 50px 0px;
    background: #FFF;
    position: relative;
    z-index: 1;
    float: left;
    width: 100%; }
    .blogPage__Box h2 {
      font-weight: normal;
      margin: 0;
      color: #000;
      font-size: 28px;
      line-height: 1.3;
      margin-bottom: 20px;
      transition: 300ms; }
      .blogPage__Box h2:hover {
        color: #3f3222; }
    .blogPage__Box h4 {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 2;
      color: #3f3222;
      font-weight: bold;
      margin: 0;
      margin-bottom: 10px;
      letter-spacing: 1.1px; }
      .blogPage__Box h4 ul {
        padding: 0;
        margin: 0; }
        .blogPage__Box h4 ul li {
          list-style: none;
          display: inline-block;
          position: relative;
          z-index: 1;
          margin-right: 10px; }
          .blogPage__Box h4 ul li:before {
            content: '';
            left: -7px;
            top: 5px;
            bottom: 5px;
            width: 1px;
            background: #f0f0f0;
            z-index: -1;
            position: absolute; }
          .blogPage__Box h4 ul li:first-of-type:before {
            display: none; }
          .blogPage__Box h4 ul li a {
            text-decoration: none;
            color: #3f3222; }
    .blogPage__Box--cover {
      float: left;
      width: 100%;
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
    .blogPage__Box--left {
      float: left;
      width: 47%;
      margin-right: 3%; }
    .blogPage__Box--right {
      float: left;
      width: 50%; }
    .blogPage__Box p {
      font-size: 18px;
      color: #000;
      font-weight: lighter;
      margin: 0;
      line-height: 1.6; }
    .blogPage__Box:before {
      left: 0px;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #f0f0f0;
      position: absolute;
      content: '';
      z-index: -1; }
    .blogPage__Box a {
      text-decoration: none;
      transition: 300ms; }
    .blogPage__Box:last-of-type:before {
      display: none; }
    .blogPage__Box .postMore {
      border: 1px #3f3222 solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #3f3222;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #3f3222;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #FFF;
      margin-top: 50px; }
      .blogPage__Box .postMore:hover {
        color: #3f3222; }
        .blogPage__Box .postMore:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .blogPage__Box .postMore:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .blogPage__Box .postMore:hover {
        color: #3f3222; }
  .blogPage__Pagination {
    float: left;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: -50px;
    left: 0; }
    .blogPage__Pagination img {
      margin-bottom: 10px; }
    .blogPage__Pagination h4 {
      font-weight: lighter;
      color: #3f3222;
      text-transform: uppercase;
      letter-spacing: 1.7px;
      font-size: 12px;
      margin: 0; }
    .blogPage__Pagination a {
      text-decoration: none; }
    .blogPage__Pagination--plus {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      line-height: 40px;
      text-align: center;
      position: relative;
      z-index: 1;
      margin: auto;
      margin-bottom: 10px;
      background: #3f3222; }
      .blogPage__Pagination--plus:before {
        content: '+';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        color: #FFF;
        font-size: 20px;
        z-index: -1; }
  .blogPage .bottomPosts .blog__Box {
    margin-bottom: 0 !important;
    margin-top: 0 !important; }
    .blogPage .bottomPosts .blog__Box:before {
      display: block; }

#lightboxOverlay {
  z-index: 100000000; }

#lightbox {
  z-index: 100000000; }

.pageSingle {
  float: left;
  width: 100%;
  background: #FFF; }
  .pageSingle__Content {
    overflow: hidden;
    position: relative;
    padding-bottom: 100px; }
  .pageSingle__Top {
    width: 100%;
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-top: 70px; }
    .pageSingle__Top h2 {
      margin: 0;
      font-size: 48px;
      color: #3f3222;
      font-weight: lighter;
      line-height: 1.2;
      max-width: 800px;
      margin: auto;
      margin-bottom: 120px;
      position: relative;
      z-index: 1; }
      .pageSingle__Top h2:before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        background: #f0f0f0;
        height: 1px; }
    .pageSingle__Top h5 {
      color: #000;
      font-weight: bold; }
    .pageSingle__Top a {
      text-decoration: none;
      font-weight: normal;
      color: #000; }
  .pageSingle__Cover {
    float: left;
    width: 100%;
    height: 450px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    margin-bottom: 50px; }
  .pageSingle__Map {
    float: left;
    width: 100%;
    height: 400px;
    margin-bottom: 50px;
    z-index: 10000000000000000; }
    .pageSingle__Map img {
      max-width: inherit !important; }
  .pageSingle__Inner {
    max-width: 665px;
    margin: auto; }
    .pageSingle__Inner h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter; }
    .pageSingle__Inner h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .pageSingle__Inner h3 {
      font-size: 22px;
      font-weight: bold; }
    .pageSingle__Inner h4, .pageSingle__Inner h5, .pageSingle__Inner h6 {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .pageSingle__Inner .wp-caption {
      width: auto !important; }
      .pageSingle__Inner .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #3f3222; }
    .pageSingle__Inner p {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .pageSingle__Inner li {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .pageSingle__Inner span {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .pageSingle__Inner a {
      font-size: 18px;
      line-height: 1.6;
      color: #3f3222;
      text-decoration: none;
      border-bottom: 1px dashed #3f3222; }
      .pageSingle__Inner a:hover {
        border-bottom: 1px solid #3f3222; }
    .pageSingle__Inner mark {
      background: #e7f5fe; }
    .pageSingle__Inner blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .pageSingle__Inner blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .pageSingle__Inner--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .pageSingle__Inner--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .pageSingle__Inner--intro a {
        color: #3f3222;
        border-bottom: 1px dotted #3f3222;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .pageSingle__Inner--intro a:hover {
          border-bottom: 1px solid #3f3222; }
    .pageSingle__Inner input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .pageSingle__Inner textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 10px;
      color: #000;
      font-weight: lighter;
      font-size: 14px; }
    .pageSingle__Inner input[type="submit"] {
      border: 1px #3f3222 solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #3f3222;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #3f3222;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #FFF;
      font-weight: 300; }
      .pageSingle__Inner input[type="submit"]:hover {
        color: #3f3222; }
        .pageSingle__Inner input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .pageSingle__Inner input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .pageSingle__Inner input[type="submit"]:hover {
        border-color: #3f3222;
        color: #3f3222;
        background: #FFF; }

.pageSingle__Inner--box {
  float: left;
  width: 46%;
  position: relative;
  z-index: 1;
  margin-left: 2%;
  margin-right: 2%; }
  .pageSingle__Inner--box:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0px;
    background: #e2e2e2;
    height: 1px;
    z-index: -1; }
  .pageSingle__Inner--box p {
    font-size: 14px; }
  .pageSingle__Inner--box:last-of-type:before {
    display: none !important; }
  .pageSingle__Inner--box:nth-last-of-type(2):before {
    display: none !important; }

.full-width {
  width: 96% !important; }
  .full-width:before {
    display: none !important; }
  .full-width:first-of-type:before {
    display: block !important; }

.fullWidth {
  width: 100%; }

.pageNavigation {
  float: left;
  width: 100%;
  background: #5ec26a;
  text-align: center;
  margin-top: 50px;
  position: relative;
  z-index: 8000; }
  .pageNavigation ul {
    padding: 0;
    margin: 0; }
    .pageNavigation ul li {
      list-style: none;
      display: inline-block;
      padding: 30px 0; }
      .pageNavigation ul li a {
        text-decoration: none;
        color: #FFF;
        text-transform: uppercase;
        transition: 300ms;
        padding: 30px 10px; }
        .pageNavigation ul li a:hover {
          background: #3E7F46; }

.colorYellow {
  background: #ebb33a !important; }
  .colorYellow a {
    background-color: transparent !important; }
    .colorYellow a:hover {
      color: #3f3222 !important; }

.fullPageNavigation {
  float: left;
  width: 100%;
  background: #e2e2e2;
  text-align: left;
  margin-top: 50px;
  position: relative;
  z-index: 8000;
  padding: 20px 0; }
  .fullPageNavigation__Inner {
    max-width: 665px;
    margin: auto; }
  .fullPageNavigation ul {
    padding: 0;
    margin: 0; }
    .fullPageNavigation ul li {
      padding: 10px 0; }
      .fullPageNavigation ul li a {
        text-decoration: none;
        color: #000;
        text-transform: uppercase;
        transition: 300ms;
        padding: 30px 10px; }
        .fullPageNavigation ul li a:hover {
          color: #5ec26a; }

.cntBox {
  float: left;
  width: 100%;
  padding: 100px 0 0 0; }
  .cntBox__Title {
    float: left;
    min-width: 110% !important;
    max-width: 110% !important;
    width: 110% !important;
    margin-left: -5% !important;
    margin-right: -5% !important;
    padding: 10px 5%;
    background: #5ec26a;
    color: #FFF;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase; }

.blockBox {
  float: left;
  width: 100%;
  margin-top: 90px;
  margin-bottom: -70px; }
  .blockBox__Inner {
    max-width: 665px;
    margin: auto; }
  .blockBox__Title {
    float: left;
    min-width: 110% !important;
    max-width: 110% !important;
    width: 110% !important;
    margin-left: -5% !important;
    margin-right: -5% !important;
    padding: 10px 5%;
    background: #5ec26a;
    color: #FFF;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase; }
  .blockBox p {
    font-size: 18px;
    line-height: 1.6;
    color: #000;
    margin-bottom: 50px; }
  .blockBox__Wraper {
    float: left;
    margin-top: 30px;
    margin-bottom: 20px; }

.postSingle {
  float: left;
  width: 100%;
  background: #FFF; }
  .postSingle__Content {
    overflow: hidden;
    position: relative;
    padding-bottom: 100px; }
  .postSingle__Map {
    float: left;
    width: 100%;
    height: 450px; }
  .postSingle__Top {
    width: 100%;
    margin: auto;
    text-align: left;
    padding: 70px 0;
    background: #3f3222; }
    .postSingle__Top h2 {
      margin: 0;
      font-size: 48px;
      color: #FFF;
      font-weight: lighter;
      line-height: 1.2;
      position: relative;
      z-index: 1;
      text-transform: uppercase;
      max-width: 665px;
      margin: auto; }
    .postSingle__Top a {
      text-decoration: none; }
  .postSingle__Cover {
    width: 100%;
    height: 450px;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-bottom: 20px solid #5ec26a; }
  .postSingle__Inner {
    max-width: 665px;
    margin: auto;
    margin-top: 70px; }
    .postSingle__Inner h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter; }
    .postSingle__Inner h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .postSingle__Inner h3 {
      font-size: 22px;
      font-weight: bold; }
    .postSingle__Inner h4, .postSingle__Inner h5, .postSingle__Inner h6 {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Inner .wp-caption {
      width: auto !important; }
      .postSingle__Inner .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #3f3222; }
    .postSingle__Inner p {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Inner li {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Inner span {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .postSingle__Inner a {
      font-size: 18px;
      line-height: 1.6;
      color: #5ec26a;
      text-decoration: none;
      font-weight: bold; }
      .postSingle__Inner a:hover {
        color: #3f3222; }
    .postSingle__Inner mark {
      background: #e7f5fe; }
    .postSingle__Inner blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .postSingle__Inner blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .postSingle__Inner tr {
      float: left;
      width: 100%;
      padding: 20px;
      background: #eeeeee; }
      .postSingle__Inner tr:nth-of-type(2n) {
        background: #e2e2e2; }
    .postSingle__Inner td {
      float: left;
      width: 100%; }
    .postSingle__Inner--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .postSingle__Inner--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .postSingle__Inner--intro a {
        color: #3f3222;
        border-bottom: 1px dotted #3f3222;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .postSingle__Inner--intro a:hover {
          border-bottom: 1px solid #3f3222; }
    .postSingle__Inner input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .postSingle__Inner table {
      min-width: 120% !important;
      max-width: 120% !important;
      width: 120% !important;
      margin-left: -10% !important;
      margin-right: -10% !important; }
    .postSingle__Inner textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      font-size: 14px; }
    .postSingle__Inner input[type="submit"] {
      border: 1px #dedede solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #dedede;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #dedede;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #000; }
      .postSingle__Inner input[type="submit"]:hover {
        color: #3f3222; }
        .postSingle__Inner input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .postSingle__Inner input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .postSingle__Inner input[type="submit"]:hover {
        background: #3f3222;
        color: #000; }
  .postSingle__Gallery {
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: 50px; }
    .postSingle__Gallery li {
      list-style: none;
      margin: 0% 2% 1% 2%;
      display: inline-block;
      text-align: center; }
      .postSingle__Gallery li span {
        font-size: 10px;
        display: block;
        margin-bottom: 15px; }
      .postSingle__Gallery li a {
        text-decoration: none;
        color: #000; }

.relatedPosts {
  padding-bottom: 150px;
  float: left;
  width: 100%;
  margin-top: 100px; }
  .relatedPosts__Title {
    margin: 0;
    font-size: 36px;
    color: #000;
    font-weight: lighter;
    line-height: 1.2;
    max-width: 800px;
    margin: auto;
    margin-bottom: 90px;
    text-align: center; }
  .relatedPosts__Subtitle {
    margin: 0;
    font-size: 12px;
    letter-spacing: 2px;
    text-align: center;
    color: #3f3222;
    margin: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: normal; }
  .relatedPosts__Inner {
    max-width: 600px;
    margin: auto; }
  .relatedPosts__Box {
    border-radius: 6px;
    padding: 50px 0px;
    background: #FFF;
    position: relative;
    z-index: 1;
    float: left;
    width: 100%; }
    .relatedPosts__Box h2 {
      font-weight: lighter;
      margin: 0;
      color: #000;
      font-size: 28px;
      line-height: 1.3;
      margin-bottom: 20px;
      transition: 300ms; }
      .relatedPosts__Box h2:hover {
        color: #004e99; }
    .relatedPosts__Box h4 {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1.1;
      color: #3f3222;
      font-weight: bold;
      margin: 0;
      margin-bottom: 10px; }
    .relatedPosts__Box p {
      font-size: 18px;
      color: #000;
      font-weight: lighter;
      margin: 0;
      line-height: 1.6; }
    .relatedPosts__Box:before {
      left: 0px;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #f0f0f0;
      position: absolute;
      content: '';
      z-index: -1; }
    .relatedPosts__Box a {
      text-decoration: none;
      transition: 300ms; }
    .relatedPosts__Box:last-of-type:before {
      display: none; }

.postSingleGray {
  float: left;
  width: 100%;
  background: #FFF; }
  .postSingleGray__Content {
    overflow: hidden;
    position: relative; }
  .postSingleGray__Top {
    width: 100%;
    margin: auto;
    text-align: left;
    padding: 70px 0;
    background: #f2f2f2; }
    .postSingleGray__Top h2 {
      margin: 0;
      font-size: 48px;
      color: #000;
      font-weight: lighter;
      line-height: 1.2;
      position: relative;
      z-index: 1;
      text-transform: uppercase; }
    .postSingleGray__Top a {
      text-decoration: none; }
  .postSingleGray__locationMap {
    height: 850px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    width: 100%;
    background-color: #f2f2f2; }
  .postSingleGray__Cover {
    width: 100%;
    height: 450px;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-bottom: 20px solid #5ec26a; }
  .postSingleGray__Inner h1 {
    font-size: 48px;
    line-height: 1.2;
    font-weight: lighter; }
  .postSingleGray__Inner h2 {
    font-size: 26px;
    line-height: 1.4;
    font-weight: lighter; }
  .postSingleGray__Inner h3 {
    font-size: 22px;
    font-weight: bold; }
  .postSingleGray__Inner h4, .postSingleGray__Inner h5, .postSingleGray__Inner h6 {
    font-size: 18px;
    line-height: 1.6;
    color: #000; }
  .postSingleGray__Inner .wp-caption {
    width: auto !important; }
    .postSingleGray__Inner .wp-caption p {
      font-size: 12px;
      font-weight: lighter;
      color: #3f3222; }
  .postSingleGray__Inner p {
    font-size: 18px;
    line-height: 1.6;
    color: #000; }
  .postSingleGray__Inner li {
    font-size: 18px;
    line-height: 1.6;
    color: #000; }
  .postSingleGray__Inner span {
    font-size: 18px;
    line-height: 1.6;
    color: #000; }
  .postSingleGray__Inner a {
    font-size: 18px;
    line-height: 1.6;
    color: #3f3222;
    text-decoration: none; }
  .postSingleGray__Inner mark {
    background: #e7f5fe; }
  .postSingleGray__Inner blockquote {
    background: #e7f5fe;
    font-weight: lighter;
    font-size: 16px;
    padding: 25px;
    color: #004e99;
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    line-height: 1.8;
    margin-bottom: 60px; }
    .postSingleGray__Inner blockquote p {
      margin: 0;
      line-height: 1.8;
      color: #004e99; }
  .postSingleGray__Inner--intro {
    margin-top: 60px;
    margin-bottom: 60px; }
    .postSingleGray__Inner--intro p {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .postSingleGray__Inner--intro a {
      color: #3f3222;
      border-bottom: 1px dotted #3f3222;
      text-decoration: none;
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
      .postSingleGray__Inner--intro a:hover {
        border-bottom: 1px solid #3f3222; }
  .postSingleGray__Inner input {
    width: 100%;
    border: 1px solid #dedede;
    padding: 0 10px;
    color: #000;
    font-weight: lighter;
    line-height: 46px;
    font-size: 14px; }
  .postSingleGray__Inner textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border: 1px solid #dedede;
    padding: 0 10px;
    color: #000;
    font-weight: lighter;
    font-size: 14px; }
  .postSingleGray__Inner input[type="submit"] {
    border: 1px #dedede solid;
    display: inline-block;
    padding: 0 30px;
    line-height: 46px;
    text-decoration: none;
    font-size: 14px;
    color: #dedede;
    position: relative;
    overflow: hidden;
    z-index: 1;
    vertical-align: bottom;
    background: #dedede;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
    letter-spacing: 1px;
    font-weight: bold;
    color: #000; }
    .postSingleGray__Inner input[type="submit"]:hover {
      color: #3f3222; }
      .postSingleGray__Inner input[type="submit"]:hover:before {
        opacity: 1;
        right: -100px;
        left: -100px; }
    .postSingleGray__Inner input[type="submit"]:before {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      top: -10px;
      bottom: -10px;
      transform: skew(40deg);
      background: #FFF;
      left: 100%;
      right: 110%;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
    .postSingleGray__Inner input[type="submit"]:hover {
      background: #3f3222;
      color: #000; }

.locations {
  float: left;
  width: 100%; }
  .locations h2 {
    text-align: center;
    font-weight: lighter;
    font-size: 60px;
    margin: 0; }
  .locations__Nav {
    text-align: center;
    height: 50px;
    line-height: 50px; }
    .locations__Nav ul {
      padding: 0;
      margin: 0;
      list-style: none; }
    .locations__Nav li {
      display: inline-block;
      color: #000;
      transition: 300ms;
      position: relative;
      padding: 0 10px;
      z-index: 1; }
      .locations__Nav li:after {
        content: '';
        position: absolute;
        top: 10px;
        bottom: 10px;
        right: -5px;
        width: 1px;
        background: #e2e2e2;
        z-index: -1; }
      .locations__Nav li:last-of-type:after {
        display: none; }
      .locations__Nav li:hover {
        cursor: pointer;
        color: #5ec26a; }
    .locations__Nav li.cusCol:after {
      background: #b1b1b1; }
    .locations__Nav li.locationCatActive {
      color: #5ec26a; }
  .locations__Wrap {
    height: 300px;
    margin: auto;
    width: 100%;
    max-width: 860px; }
  .locations__Box {
    float: left;
    width: 100%;
    position: relative; }
    .locations__Box .center {
      position: relative;
      min-height: 400px;
      padding-left: 100px;
      padding-right: 100px;
      padding: 50px 0; }
    .locations__Box a {
      text-decoration: none; }
    .locations__Box:nth-of-type(2n) {
      background: #f2f2f2; }
    .locations__Box--img {
      float: left;
      width: 30%;
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative; }
      .locations__Box--img a {
        float: left;
        width: 100%;
        height: 200px; }
    .locations__Box--content {
      width: 65%;
      margin-left: 5%;
      float: left;
      position: relative;
      padding-right: 15%; }
      .locations__Box--content h4 {
        font-size: 30px;
        color: #000;
        font-weight: bold;
        margin: 0;
        margin-top: -10px;
        margin-bottom: 10px; }
      .locations__Box--content p {
        color: #3f3222;
        margin: 2px 0; }
      .locations__Box--content .lastParagraph {
        margin-top: 20px;
        margin-bottom: 40px; }
      .locations__Box--content span {
        color: #000;
        font-weight: bold;
        margin-right: 3px; }
      .locations__Box--content--more {
        background: #ebb33a;
        color: #3f3222;
        text-transform: uppercase;
        padding-left: 40px;
        padding-right: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        border: 0;
        display: inline-block;
        text-decoration: none;
        transition: 300ms; }
        .locations__Box--content--more:hover {
          color: #FFF;
          background: #3f3222; }

.partsSingle {
  float: left;
  width: 100%;
  background: #FFF; }
  .partsSingle__Content {
    overflow: hidden;
    position: relative; }
  .partsSingle__Top {
    width: 100%;
    margin: auto;
    text-align: left;
    padding: 70px 0;
    background: #3f3222; }
    .partsSingle__Top h2 {
      margin: 0;
      font-size: 48px;
      color: #FFF;
      font-weight: lighter;
      line-height: 1.2;
      position: relative;
      z-index: 1;
      text-transform: uppercase; }
    .partsSingle__Top a {
      text-decoration: none; }
  .partsSingle__Cover {
    width: 100%;
    height: 450px;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-bottom: 20px solid #5ec26a; }
  .partsSingle__Inner {
    margin-top: 70px; }
    .partsSingle__Inner h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter;
      color: #FFF; }
    .partsSingle__Inner h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter;
      color: #FFF; }
    .partsSingle__Inner h3 {
      font-size: 22px;
      font-weight: bold;
      color: #FFF; }
    .partsSingle__Inner h4, .partsSingle__Inner h5, .partsSingle__Inner h6 {
      font-size: 18px;
      line-height: 1.6;
      color: #FFF; }
    .partsSingle__Inner .wp-caption {
      width: auto !important; }
      .partsSingle__Inner .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #FFF; }
    .partsSingle__Inner p {
      font-size: 18px;
      line-height: 1.6;
      color: #FFF; }
    .partsSingle__Inner li {
      font-size: 18px;
      line-height: 1.6;
      color: #FFF; }
    .partsSingle__Inner span {
      font-size: 18px;
      line-height: 1.6;
      color: #FFF; }
    .partsSingle__Inner a {
      font-size: 18px;
      line-height: 1.6;
      color: #FFF;
      text-decoration: none; }
    .partsSingle__Inner mark {
      background: #e7f5fe; }
    .partsSingle__Inner blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .partsSingle__Inner blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .partsSingle__Inner--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .partsSingle__Inner--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .partsSingle__Inner--intro a {
        color: #3f3222;
        border-bottom: 1px dotted #3f3222;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .partsSingle__Inner--intro a:hover {
          border-bottom: 1px solid #3f3222; }
    .partsSingle__Inner input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #FFF;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .partsSingle__Inner textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #FFF;
      font-weight: lighter;
      font-size: 14px; }
    .partsSingle__Inner input[type="submit"] {
      border: 1px #dedede solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #dedede;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #dedede;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #FFF; }
      .partsSingle__Inner input[type="submit"]:hover {
        color: #3f3222; }
        .partsSingle__Inner input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .partsSingle__Inner input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .partsSingle__Inner input[type="submit"]:hover {
        background: #3f3222;
        color: #FFF; }

body {
  counter-reset: item; }

.topBox {
  float: left;
  width: 100%; }
  .topBox__Description {
    padding: 60px 0;
    float: left;
    width: 100%;
    background: #5ec26a; }
    .topBox__Description--colored {
      background: #f2f2f2; }
      .topBox__Description--colored .topBox__Description--title {
        background: #e2e2e2;
        float: left;
        margin-top: -60px;
        padding: 10px 20px;
        width: 530px; }
        .topBox__Description--colored .topBox__Description--title h3 {
          color: #000;
          margin: 0;
          text-transform: uppercase; }
      .topBox__Description--colored .topBox__Description--content p {
        color: #3f3222; }
    .topBox__Description--wrap {
      float: left;
      width: 100%; }
    .topBox__Description--title {
      background: #ebb33a;
      float: left;
      margin-top: -60px;
      padding: 10px 20px;
      width: 540px; }
      .topBox__Description--title h3 {
        color: #FFF;
        margin: 0;
        text-transform: uppercase; }
    .topBox__Description--image {
      float: left;
      width: 30%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-top: 40px; }
    .topBox__Description--content {
      float: left;
      width: 65%;
      margin-left: 5%;
      margin-top: 40px; }
      .topBox__Description--content p {
        margin-top: 0;
        color: #FFF; }
      .topBox__Description--content--more {
        background: #ebb33a;
        color: #3f3222;
        text-transform: uppercase;
        padding-left: 40px;
        padding-right: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        border: 0;
        display: inline-block;
        text-decoration: none;
        transition: 300ms;
        float: right;
        margin-top: 60px; }
        .topBox__Description--content--more:hover {
          color: #FFF;
          background: #3f3222; }
  .topBox__Locations {
    background: #f2f2f2;
    float: left;
    width: 100%;
    padding: 30px 0; }
    .topBox__Locations ol {
      padding: 0;
      margin: 0;
      column-count: 4;
      text-align: left;
      max-width: 920px;
      margin: auto;
      list-style: none; }
    .topBox__Locations--doubled {
      column-count: 3 !important;
      max-width: 820px !important; }
    .topBox__Locations li {
      padding-left: 1em;
      text-indent: -.7em;
      margin-bottom: 5px;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      counter-increment: item; }
      .topBox__Locations li:before {
        margin-right: 5px;
        font-size: 14px;
        content: counter(item) ".";
        color: #AD0A0A;
        text-align: center;
        display: inline-block; }
    .topBox__Locations a {
      text-decoration: none;
      color: #3f3222;
      border-bottom: 1px solid transparent;
      font-size: 13px; }
      .topBox__Locations a:hover {
        border-color: #ebb33a; }
  .topBox__locationMap {
    height: 850px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    width: 100%;
    background-color: #f2f2f2; }

.middleBox {
  float: left;
  width: 100%; }
  .middleBox__Description {
    padding: 60px 0;
    float: left;
    width: 100%;
    background: #3f3222; }
    .middleBox__Description--title {
      background: #5ec26a;
      float: left;
      margin-top: -60px;
      padding: 10px 300px 10px 20px; }
      .middleBox__Description--title h3 {
        color: #FFF;
        margin: 0; }
    .middleBox__Description--image {
      float: left;
      width: 30%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-top: 40px; }
    .middleBox__Description--content {
      float: left;
      width: 65%;
      margin-left: 5%;
      margin-top: 40px; }
      .middleBox__Description--content p {
        margin-top: 0;
        color: #FFF; }
      .middleBox__Description--content--more {
        background: #ebb33a;
        color: #3f3222;
        text-transform: uppercase;
        padding-left: 40px;
        padding-right: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        border: 0;
        display: inline-block;
        text-decoration: none;
        transition: 300ms;
        float: right;
        margin-top: 60px; }
        .middleBox__Description--content--more:hover {
          color: #FFF;
          background: #3f3222; }
  .middleBox__Locations {
    background: #FFF;
    float: left;
    width: 100%;
    padding: 30px 0; }
    .middleBox__Locations .center {
      max-width: 860px; }
    .middleBox__Locations ul {
      padding: 0;
      margin: 0;
      text-align: left;
      float: left;
      width: 30%;
      margin-left: 1.5%;
      margin-right: 1.5%;
      list-style: none; }
      .middleBox__Locations ul h3 {
        text-transform: uppercase;
        font-weight: lighter;
        font-size: 28px;
        margin-bottom: 0; }
    .middleBox__Locations li {
      padding-left: 1em;
      text-indent: -.7em;
      margin-bottom: 5px; }
      .middleBox__Locations li:before {
        content: "• ";
        color: #ebb33a;
        font-size: 18px; }
    .middleBox__Locations a {
      text-decoration: none;
      color: #3f3222;
      border-bottom: 1px solid transparent; }
      .middleBox__Locations a:hover {
        border-color: #ebb33a; }
    .middleBox__Locations--image {
      float: left;
      width: 100%;
      height: 180px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-top: 50px; }
  .middleBox__locationMap {
    height: 850px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    width: 100%;
    background-color: #f2f2f2; }

.bottomBox {
  float: left;
  width: 100%; }
  .bottomBox__Description {
    padding: 60px 0;
    float: left;
    width: 100%;
    background: #f2f2f2; }
    .bottomBox__Description--wrap {
      float: left;
      width: 100%; }
    .bottomBox__Description--title {
      background: #e2e2e2;
      float: left;
      margin-top: -60px;
      padding: 10px 20px;
      width: 530px; }
      .bottomBox__Description--title h3 {
        color: #000;
        margin: 0;
        text-transform: uppercase; }
    .bottomBox__Description--image {
      float: left;
      width: 30%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-top: 40px; }
    .bottomBox__Description--content {
      float: left;
      width: 65%;
      margin-left: 5%;
      margin-top: 40px; }
      .bottomBox__Description--content p {
        margin-top: 0;
        color: #000; }
      .bottomBox__Description--content--more {
        background: #ebb33a;
        color: #3f3222;
        text-transform: uppercase;
        padding-left: 40px;
        padding-right: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        border: 0;
        display: inline-block;
        text-decoration: none;
        transition: 300ms;
        float: right;
        margin-top: 60px; }
        .bottomBox__Description--content--more:hover {
          color: #FFF;
          background: #3f3222; }
    .bottomBox__Description--full {
      width: 100%;
      margin-left: 0; }
  .bottomBox__Locations {
    background: #f2f2f2;
    float: left;
    width: 100%;
    padding: 30px 0; }
    .bottomBox__Locations ul {
      padding: 0;
      margin: 0;
      column-count: 4;
      text-align: center;
      list-style: none; }
    .bottomBox__Locations li {
      padding-left: 1em;
      text-indent: -.7em;
      margin-bottom: 5px; }
      .bottomBox__Locations li:before {
        content: "• ";
        color: #ebb33a;
        font-size: 18px; }
    .bottomBox__Locations a {
      text-decoration: none;
      color: #3f3222;
      border-bottom: 1px solid transparent; }
      .bottomBox__Locations a:hover {
        border-color: #ebb33a; }
  .bottomBox__Cnt {
    background: #f2f2f2;
    float: left;
    width: 100%;
    padding: 30px 0; }
    .bottomBox__Cnt article {
      margin-bottom: 50px; }
      .bottomBox__Cnt article h3 i {
        font-weight: lighter;
        font-size: 15px; }
  .bottomBox__locationMap {
    height: 650px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    width: 100%;
    background-color: #f2f2f2; }

.footerBox {
  float: left;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1; }
  .footerBox:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.3;
    z-index: -1; }
  .footerBox__Box {
    float: left;
    width: 100%;
    position: relative; }
    .footerBox__Box .center {
      border-top: 1px solid #FFF;
      padding: 60px 0;
      max-width: 860px; }
    .footerBox__Box:first-of-type .center {
      border-top: 0; }
    .footerBox__Box h2 {
      color: #FFF; }
    .footerBox__Box p {
      color: #FFF; }
    .footerBox__Box--img {
      position: absolute;
      left: 0;
      bottom: 60px;
      max-height: 70px; }
    .footerBox__Box--more {
      background: #ebb33a;
      color: #3f3222;
      text-transform: uppercase;
      padding-left: 40px;
      padding-right: 40px;
      height: 40px;
      line-height: 40px;
      font-weight: normal;
      border: 0;
      display: inline-block;
      text-decoration: none;
      transition: 300ms;
      float: right;
      margin-top: 60px; }
      .footerBox__Box--more:hover {
        color: #FFF;
        background: #3f3222; }
  .footerBox .addedGreen {
    background: #5ec26a; }
    .footerBox .addedGreen .center {
      border-top: 0; }

.customWidth {
  padding: 50px 0; }
  .customWidth__Left {
    width: 48%;
    margin-right: 2%;
    float: left; }
  .customWidth__Right {
    width: 48%;
    margin-left: 2%;
    float: left; }
    .customWidth__Right input[type="text"] {
      width: 100%; }
    .customWidth__Right input[type="email"] {
      width: 100%; }
    .customWidth__Right textarea {
      min-width: 100%;
      max-width: 100%;
      height: 100px;
      width: 100%; }
    .customWidth__Right input[type="submit"] {
      background: #ebb33a;
      color: #3f3222;
      text-transform: uppercase;
      padding-left: 40px;
      padding-right: 40px;
      height: 40px;
      line-height: 40px;
      font-weight: normal;
      border: 0;
      display: inline-block;
      text-decoration: none;
      transition: 300ms;
      float: left; }
      .customWidth__Right input[type="submit"]:hover {
        color: #FFF;
        background: #3f3222; }

.paddingRemover {
  padding-bottom: 0 !important; }

.productSingle {
  float: left;
  width: 100%;
  background: #FFF; }
  .productSingle__Content {
    overflow: hidden;
    position: relative;
    padding-bottom: 100px; }
  .productSingle__Top {
    width: 100%;
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-top: 70px; }
    .productSingle__Top h6 {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      color: #3f3222;
      margin-top: 0;
      margin-bottom: 0;
      letter-spacing: 1px; }
    .productSingle__Top h6.price {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: bold;
      color: #000;
      margin-top: 0;
      letter-spacing: 1px;
      margin-bottom: 25px; }
    .productSingle__Top h4.date {
      font-size: 14px;
      font-weight: lighter;
      color: #3f3222;
      margin: 0;
      margin-bottom: 40px; }
    .productSingle__Top h2 {
      margin: 0;
      font-size: 38px;
      color: #3f3222;
      font-weight: lighter;
      line-height: 1.2;
      max-width: 800px;
      margin: auto;
      margin-bottom: 120px;
      position: relative;
      z-index: 1; }
      .productSingle__Top h2:before {
        content: '';
        position: absolute;
        bottom: -80px;
        left: 0;
        right: 0;
        background: #f0f0f0;
        height: 1px; }
    .productSingle__Top a {
      text-decoration: none; }
  .productSingle__Cover {
    float: left;
    width: 70%;
    margin-left: 15%;
    height: 450px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    margin-bottom: 50px; }
  .productSingle__Inner {
    max-width: 665px;
    margin: auto;
    position: relative; }
    .productSingle__Inner h1 {
      font-size: 48px;
      line-height: 1.2;
      font-weight: lighter; }
    .productSingle__Inner h2 {
      font-size: 26px;
      line-height: 1.4;
      font-weight: lighter; }
    .productSingle__Inner h3 {
      font-size: 22px;
      font-weight: bold; }
    .productSingle__Inner h4, .productSingle__Inner h5, .productSingle__Inner h6 {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .productSingle__Inner .wp-caption {
      width: auto !important; }
      .productSingle__Inner .wp-caption p {
        font-size: 12px;
        font-weight: lighter;
        color: #3f3222; }
    .productSingle__Inner p {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .productSingle__Inner li {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .productSingle__Inner span {
      font-size: 18px;
      line-height: 1.6;
      color: #000; }
    .productSingle__Inner a {
      font-size: 18px;
      line-height: 1.6;
      color: #3f3222;
      text-decoration: none; }
    .productSingle__Inner img {
      min-width: 765px;
      margin-left: -100px; }
    .productSingle__Inner mark {
      background: #e7f5fe; }
    .productSingle__Inner blockquote {
      background: #e7f5fe;
      font-weight: lighter;
      font-size: 16px;
      padding: 25px;
      color: #004e99;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      line-height: 1.8;
      margin-bottom: 60px; }
      .productSingle__Inner blockquote p {
        margin: 0;
        line-height: 1.8;
        color: #004e99; }
    .productSingle__Inner--intro {
      margin-top: 60px;
      margin-bottom: 60px; }
      .productSingle__Inner--intro p {
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
      .productSingle__Inner--intro a {
        color: #3f3222;
        border-bottom: 1px dotted #3f3222;
        text-decoration: none;
        font-size: 26px;
        line-height: 1.4;
        font-weight: lighter; }
        .productSingle__Inner--intro a:hover {
          border-bottom: 1px solid #3f3222; }
    .productSingle__Inner--gallery {
      float: left;
      width: 100%;
      padding: 50px 0; }
      .productSingle__Inner--gallery a {
        float: left;
        width: 23%;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 1%;
        height: 150px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center; }
    .productSingle__Inner iframe {
      width: 100%;
      height: 400px; }
    .productSingle__Inner input {
      width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      line-height: 46px;
      font-size: 14px; }
    .productSingle__Inner textarea {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #dedede;
      padding: 0 10px;
      color: #000;
      font-weight: lighter;
      font-size: 14px; }
    .productSingle__Inner input[type="submit"] {
      border: 1px #f0f0f0 solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #f0f0f0;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #f0f0f0;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #000; }
      .productSingle__Inner input[type="submit"]:hover {
        color: #3f3222; }
        .productSingle__Inner input[type="submit"]:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .productSingle__Inner input[type="submit"]:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .productSingle__Inner input[type="submit"]:hover {
        background: #3f3222;
        color: #000; }
    .productSingle__Inner table {
      width: 100% !important;
      display: table; }
      .productSingle__Inner table tr:nth-of-type(2n-1) {
        background: #e2e2e2; }
      .productSingle__Inner table td {
        display: table-cell;
        width: auto !important;
        height: 40px !important;
        line-height: 40px !important;
        background-color: transparent !important;
        padding: 0 10px; }
      .productSingle__Inner table td:empty {
        display: none; }

.relatedProducts {
  padding-bottom: 150px;
  float: left;
  width: 100%;
  margin-top: 100px; }
  .relatedProducts__Title {
    margin: 0;
    font-size: 36px;
    color: #000;
    font-weight: lighter;
    line-height: 1.2;
    max-width: 800px;
    margin: auto;
    margin-bottom: 90px;
    text-align: center; }
  .relatedProducts__Subtitle {
    margin: 0;
    font-size: 12px;
    letter-spacing: 2px;
    text-align: center;
    color: #3f3222;
    margin: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: normal; }
  .relatedProducts__Inner {
    max-width: 600px;
    margin: auto; }
  .relatedProducts__Box {
    border-radius: 6px;
    padding: 50px 0px;
    background: #FFF;
    position: relative;
    z-index: 1;
    float: left;
    width: 48%;
    margin-left: 1%;
    margin-right: 1%; }
    .relatedProducts__Box h2 {
      font-weight: lighter;
      margin: 0;
      color: #000;
      font-size: 20px;
      line-height: 1.3;
      transition: 300ms;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      position: relative; }
      .relatedProducts__Box h2:hover {
        color: #004e99; }
    .relatedProducts__Box h4 {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1.1;
      color: #3f3222;
      font-weight: bold;
      margin: 0;
      margin-bottom: 10px;
      text-align: center; }
    .relatedProducts__Box p {
      font-size: 18px;
      color: #000;
      font-weight: lighter;
      margin: 0;
      line-height: 1.6; }
    .relatedProducts__Box:before {
      left: 0px;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #f0f0f0;
      position: absolute;
      content: '';
      z-index: -1; }
    .relatedProducts__Box a {
      text-decoration: none;
      transition: 300ms; }
    .relatedProducts__Box--titleLink {
      height: 100px;
      float: left;
      width: 100%;
      margin-bottom: 20px; }
    .relatedProducts__Box--image {
      float: left;
      width: 100%;
      height: 200px;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center; }
    .relatedProducts__Box--more {
      border: 1px #3f3222 solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #3f3222;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #3f3222;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #FFF;
      margin-top: 50px;
      float: none;
      margin: auto; }
      .relatedProducts__Box--more:hover {
        color: #3f3222; }
        .relatedProducts__Box--more:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .relatedProducts__Box--more:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .relatedProducts__Box--more:hover {
        color: #3f3222; }

.categoryProducts {
  padding-bottom: 150px;
  float: left;
  width: 100%;
  margin-top: 100px; }
  .categoryProducts__Title {
    margin: 0;
    font-size: 36px;
    color: #000;
    font-weight: lighter;
    line-height: 1.2;
    max-width: 800px;
    margin: auto;
    margin-bottom: 90px;
    text-align: center; }
  .categoryProducts__Subtitle {
    margin: 0;
    font-size: 12px;
    letter-spacing: 2px;
    text-align: center;
    color: #3f3222;
    margin: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: normal; }
  .categoryProducts__Inner {
    max-width: 600px;
    margin: auto; }
  .categoryProducts__Box {
    border-radius: 6px;
    padding: 50px 0px;
    background: #FFF;
    position: relative;
    z-index: 1;
    float: left;
    width: 31%;
    margin-left: 1%;
    margin-right: 1%; }
    .categoryProducts__Box h2 {
      font-weight: lighter;
      margin: 0;
      color: #000;
      font-size: 28px;
      line-height: 1.3;
      margin-bottom: 20px;
      transition: 300ms;
      text-align: center; }
      .categoryProducts__Box h2:hover {
        color: #004e99; }
    .categoryProducts__Box h4 {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1.1;
      color: #3f3222;
      font-weight: bold;
      margin: 0;
      margin-bottom: 10px;
      text-align: center; }
    .categoryProducts__Box p {
      font-size: 18px;
      color: #000;
      font-weight: lighter;
      margin: 0;
      line-height: 1.6; }
    .categoryProducts__Box:before {
      left: 0px;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #f0f0f0;
      position: absolute;
      content: '';
      z-index: -1; }
    .categoryProducts__Box a {
      text-decoration: none;
      transition: 300ms; }
    .categoryProducts__Box--image {
      float: left;
      width: 100%;
      height: 200px;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center; }
    .categoryProducts__Box--more {
      border: 1px #3f3222 solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      font-size: 14px;
      color: #3f3222;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #3f3222;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      letter-spacing: 1px;
      font-weight: bold;
      color: #FFF;
      margin-top: 50px;
      transform: translateX(-50%);
      left: 50%; }
      .categoryProducts__Box--more:hover {
        color: #3f3222; }
        .categoryProducts__Box--more:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .categoryProducts__Box--more:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(40deg);
        background: #FFF;
        left: 100%;
        right: 110%;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
      .categoryProducts__Box--more:hover {
        color: #3f3222; }

.machineCategory {
  float: left;
  width: 100%;
  background: #FFF; }
  .machineCategory__Content {
    overflow: hidden;
    position: relative;
    padding-bottom: 100px; }
  .machineCategory__Top {
    width: 100%;
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-top: 70px; }
    .machineCategory__Top h2 {
      margin: 0;
      font-size: 48px;
      color: #3f3222;
      font-weight: lighter;
      line-height: 1.2;
      max-width: 800px;
      margin: auto;
      margin-bottom: 10px;
      position: relative;
      z-index: 1; }
      .machineCategory__Top h2:before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        background: #f0f0f0;
        height: 1px; }
      .machineCategory__Top h2:hover {
        color: #000;
        cursor: pointer; }
    .machineCategory__Top h5 {
      margin: 0;
      color: #3f3222;
      font-size: 16px;
      display: inline-block;
      margin-bottom: 70px;
      font-weight: lighter;
      line-height: 1.3;
      text-transform: uppercase;
      margin: 0 5px;
      margin-bottom: 90px; }
      .machineCategory__Top h5:hover {
        color: #000;
        cursor: pointer; }
    .machineCategory__Top .activeProducts {
      color: #000; }
    .machineCategory__Top a {
      text-decoration: none; }
  .machineCategory__Cover {
    float: left;
    width: 100%;
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 50px; }
  .machineCategory__Box {
    margin-left: 2%;
    margin-right: 2%;
    float: left;
    width: 29%;
    margin-bottom: 40px;
    min-height: 360px; }
    .machineCategory__Box a {
      text-decoration: none; }
    .machineCategory__Box h4 {
      margin: 0;
      min-height: 50px;
      color: #000; }
    .machineCategory__Box h5 {
      margin: 0;
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 10px;
      color: #000; }
    .machineCategory__Box p {
      font-size: 14px;
      color: #000; }
    .machineCategory__Box--img {
      float: left;
      width: 100%;
      height: 170px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-bottom: 10px; }

.machineCategory__Inner--box {
  float: left;
  width: 46%;
  position: relative;
  z-index: 1;
  margin-left: 2%;
  margin-right: 2%; }
  .machineCategory__Inner--box p {
    font-size: 14px; }

.full-width {
  width: 96% !important; }

.lb-number {
  display: none !important; }

.productFormOutbox {
  float: left;
  width: 100%;
  position: relative; }

.productForm {
  position: absolute;
  background: #FFF;
  width: 665px;
  z-index: -10000000000;
  opacity: 0;
  top: -350px;
  padding: 50px;
  padding-bottom: 0;
  transition: opacity 500ms; }
  .productForm label {
    font-size: 13px;
    font-weight: lighter; }
  .productForm input {
    margin-bottom: 10px;
    height: 30px; }
  .productForm input[type="submit"] {
    height: 50px; }
  .productForm textarea {
    margin-bottom: 10px; }
  .productForm__Call {
    border: 1px #3f3222 solid;
    display: inline-block;
    padding: 0 30px;
    line-height: 46px;
    text-decoration: none;
    font-size: 14px;
    color: #3f3222;
    position: relative;
    overflow: hidden;
    z-index: 1;
    vertical-align: bottom;
    background: transparent;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
    letter-spacing: 1px;
    font-weight: bold;
    color: #3f3222 !important;
    font-size: 14px;
    font-weight: lighter;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 50px; }
    .productForm__Call:hover {
      color: #3f3222; }
      .productForm__Call:hover:before {
        opacity: 1;
        right: -100px;
        left: -100px; }
    .productForm__Call:before {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      top: -10px;
      bottom: -10px;
      transform: skew(40deg);
      background: #FFF;
      left: 100%;
      right: 110%;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
    .productForm__Call:before {
      background: #3f3222; }
    .productForm__Call:hover {
      color: #FFF !important;
      cursor: pointer; }
  .productForm__Close {
    position: absolute;
    float: right;
    right: 50px;
    transition: 500ms;
    top: 30px; }
    .productForm__Close:hover {
      color: #3f3222;
      transform: rotate(180deg) scale(1.3);
      cursor: pointer; }

.activeForm {
  opacity: 1;
  z-index: 10000000000; }

.activeBody {
  position: relative;
  z-index: 1;
  float: left;
  width: 100%;
  transition: opacity 500ms; }
  .activeBody:before {
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #000;
    opacity: 0.5;
    z-index: 1000000000;
    position: absolute; }

.productBox {
  width: 24%;
  margin-bottom: 1%;
  margin-right: 0.5%;
  margin-left: 0.5%;
  text-align: center;
  line-height: 180px;
  float: left; }
  .productBox a {
    border: 0;
    float: left;
    width: 100%; }
    .productBox a:hover {
      border: 0; }
    .productBox a h2 {
      font-size: 18px; }
      .productBox a h2:hover {
        color: #ebb33a; }
  .productBox__Box {
    float: left;
    width: 100%;
    height: 180px;
    background: #FFF;
    transition: 300ms;
    border: 1px solid #000 !important;
    position: relative;
    text-align: center;
    margin: 0; }
    .productBox__Box img {
      width: 100%;
      vertical-align: middle;
      line-height: 300px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      margin-left: 0 !important;
      min-width: auto !important; }
    .productBox__Box img.whiteIcon {
      opacity: 0; }
    .productBox__Box img.normalIcon {
      opacity: 1; }
    .productBox__Box:hover {
      border: 1px solid #ebb33a !important;
      background: #ebb33a; }
      .productBox__Box:hover img.whiteIcon {
        opacity: 1; }
      .productBox__Box:hover img.normalIcon {
        opacity: 0; }

.categoryBox {
  width: 48%;
  margin-bottom: 1%;
  margin-right: 1%;
  margin-left: 1%;
  text-align: center;
  line-height: 180px;
  float: left; }
  .categoryBox a {
    border: 0;
    float: left;
    width: 100%; }
    .categoryBox a:hover {
      border: 0; }
    .categoryBox a h2 {
      font-size: 18px; }
      .categoryBox a h2:hover {
        color: #ebb33a; }
  .categoryBox__Box {
    float: left;
    width: 100%;
    height: 300px;
    background: #FFF;
    transition: 300ms;
    position: relative;
    text-align: center;
    margin: 0;
    overflow: hidden; }
    .categoryBox__Box img {
      width: auto;
      vertical-align: middle;
      line-height: 300px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%) scale(0.8);
      left: 50%;
      margin-left: 0 !important;
      min-width: auto !important;
      transition: 300ms;
      height: 100%;
      max-width: initial !important; }
    .categoryBox__Box:hover img.normalIcon {
      top: 50%;
      transform: translateY(-50%) translateX(-50%) scale(0.9); }

.defineHeight {
  min-height: 530px; }

.siteHeader {
  position: fixed;
  float: left;
  width: 100%;
  z-index: 9000;
  height: 100px;
  background: rgba(0, 0, 0, 0.6); }
  .siteHeader__Logo {
    float: left;
    width: 12%;
    position: relative;
    z-index: 100;
    text-align: left;
    line-height: 100px; }
    .siteHeader__Logo img {
      vertical-align: middle;
      max-height: 80px; }

.js-SiteHeader {
  transition: background .7s ease-in-out; }
  .js-SiteHeader--is-Fixed {
    position: fixed;
    left: 0px;
    width: 100%;
    top: 0px;
    background: #000;
    height: 100px; }

.siteFooter {
  float: left;
  width: 100%;
  background: #3f3222;
  padding: 60px 0; }
  .siteFooter__Wrap {
    width: 100%;
    max-width: 860px;
    margin: auto; }
  .siteFooter__Widget {
    width: 30%; }
    .siteFooter__Widget:nth-of-type(1) {
      float: left; }
    .siteFooter__Widget:nth-of-type(3) {
      float: right; }
    .siteFooter__Widget:nth-of-type(2) {
      margin-left: 5%;
      float: left;
      margin-right: 5%; }
    .siteFooter__Widget h2 {
      font-size: 18px;
      color: #FFF;
      font-weight: lighter; }
    .siteFooter__Widget p {
      font-size: 14px;
      color: #FFF;
      font-weight: lighter; }
    .siteFooter__Widget a {
      font-size: 14px;
      color: #FFF;
      text-decoration: none;
      font-weight: lighter; }
    .siteFooter__Widget i {
      margin-right: 10px;
      width: 20px; }
    .siteFooter__Widget img {
      max-width: 150px; }
    .siteFooter__Widget li {
      list-style: none;
      display: block; }
    .siteFooter__Widget ul {
      padding: 0;
      margin: 0; }

.nav {
  position: relative;
  font-size: 16px;
  float: left;
  text-align: center;
  width: 88%;
  left: 0;
  z-index: 10; }
  .nav ul {
    padding: 0;
    margin: 0 auto;
    list-style-type: none;
    text-align: left;
    display: block;
    height: 100px;
    line-height: 100px;
    float: right; }
  .nav li {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    position: relative; }
    .nav li ul {
      position: absolute;
      top: 88px;
      line-height: 1 !important;
      min-width: 300px;
      height: 100%;
      display: block;
      float: left;
      left: 20px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(10px);
      transition: 300ms; }
      .nav li ul li {
        display: block;
        text-align: left;
        background: rgba(0, 0, 0, 0.6);
        padding-left: 10px; }
        .nav li ul li:first-of-type {
          padding-top: 10px; }
        .nav li ul li:last-of-type {
          padding-bottom: 10px; }
        .nav li ul li a {
          line-height: 1.8 !important;
          padding: 0 !important;
          font-size: 13px; }
        .nav li ul li ul {
          position: absolute;
          top: 0;
          left: 300px;
          visibility: hidden;
          opacity: 0; }
          .nav li ul li ul li {
            background: rgba(0, 0, 0, 0.8); }
            .nav li ul li ul li a {
              text-transform: initial; }
    .nav li:hover ul {
      transform: translateY(0);
      opacity: 1;
      visibility: visible; }
    .nav li ul li ul {
      visibility: hidden !important;
      opacity: 0 !important;
      display: block !important; }
    .nav li ul li:hover ul {
      transform: translateY(0);
      opacity: 1 !important;
      visibility: visible !important; }
  .nav a {
    color: #FFF;
    font-size: 16px;
    text-decoration: none;
    line-height: 80px;
    margin: 0;
    display: block;
    position: relative;
    transition: 300ms;
    padding: 0 20px;
    font-weight: lighter;
    z-index: 1;
    text-transform: uppercase;
    letter-spacing: 1.5px; }
    .nav a:hover {
      color: #5ec26a; }
  .nav li.current_page_item a {
    color: #5ec26a; }
  .nav li.current_page_item ul li a {
    color: #FFF !important; }
    .nav li.current_page_item ul li a:hover {
      color: #5ec26a !important; }
  .nav li.current_page_item ul li ul li a {
    color: #FFF !important; }
    .nav li.current_page_item ul li ul li a:hover {
      color: #5ec26a !important; }

.mobile {
  display: none !important; }

@media screen and (max-width: 800px) {
  .productForm {
    width: 100%;
    padding: 10px;
    top: -300px; }
    .productForm__Close {
      top: 10px;
      right: 10px; }
  .mobile {
    display: inline-block !important; }
  .site {
    padding-top: 0; }
  .siteHeader {
    height: auto;
    position: relative;
    margin-bottom: 0;
    top: 0 !important;
    transform: translateY(0) !important; }
    .siteHeader__Top {
      height: auto;
      line-height: 1; }
    .siteHeader__Left {
      display: none; }
    .siteHeader__Right {
      display: none; }
    .siteHeader__Logo {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: right;
      padding-right: 15px;
      line-height: initial; }
      .siteHeader__Logo img {
        max-width: 90px; }
  .nav {
    position: absolute;
    background: #3f3222;
    transform: translateX(-100%);
    z-index: 1000000000;
    transition: 500ms !important;
    width: 100%; }
    .nav ul {
      height: 100vh;
      line-height: 1;
      display: block;
      background: #3f3222;
      float: left;
      padding-top: 30px; }
    .nav li {
      display: table;
      float: left;
      width: 100%;
      margin: 0;
      padding: 0;
      text-align: left;
      padding-left: 0px;
      background: #3f3222; }
      .nav li ul {
        position: relative;
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        top: 0;
        left: 0;
        padding-top: 0; }
        .nav li ul li {
          background-color: transparent;
          padding-left: 30px; }
          .nav li ul li ul {
            position: relative;
            visibility: visible !important;
            opacity: 1 !important;
            left: 0;
            top: 0;
            padding-top: 0; }
            .nav li ul li ul li {
              background-color: transparent; }
    .nav a {
      padding: 0 20px;
      line-height: 3; }
  .callNav {
    position: absolute;
    right: -55px;
    top: 20px;
    padding: 10px 15px;
    background: transparent;
    cursor: pointer;
    z-index: 10000000000000; }
    .callNav i {
      color: #ebb33a;
      transition: 700ms;
      font-size: 30px; }
  .activeNav {
    transform: translateX(0%);
    opacity: 1;
    transition: 500ms !important; }
    .activeNav .callNav {
      right: 10px; }
      .activeNav .callNav i {
        color: #ebb33a;
        transform: rotate(180deg); }
        .activeNav .callNav i:before {
          content: "\f00d" !important; }
  .homeSlider h2 {
    font-size: 34px; }
  .homeSlider h4 {
    font-size: 24px; }
  .homeSlider__Slide {
    background-size: auto 100%;
    background-position: center center !important;
    background-attachment: initial !important; }
  .topLeft {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .topCenter {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .topRight {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .centerLeft {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .centerCenter {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center; }
  .centerRight {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .bottomLeft {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .bottomCenter {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .bottomRight {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important; }
  .threewidgets__Box {
    width: 100%;
    margin: 0; }
  .sixbox {
    background-size: auto 100%;
    background-position: center center !important;
    background-attachment: initial !important; }
    .sixbox__Box {
      width: 90% !important;
      margin-left: 5% !important;
      margin-right: 5% !important;
      margin-top: 0 !important;
      margin-bottom: 5% !important;
      height: 200px !important; }
      .sixbox__Box h3 {
        color: #3f3222 !important;
        position: absolute !important;
        top: 134px !important;
        left: 0 !important;
        right: 0 !important;
        padding: 10px 0 !important;
        z-index: 1 !important;
        font-weight: normal !important;
        transition: 300ms !important; }
        .sixbox__Box h3:before {
          position: absolute !important;
          content: '' !important;
          top: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          left: 0 !important;
          background: #FFF !important;
          z-index: -1 !important;
          opacity: 0.7 !important; }
      .sixbox__Box p {
        display: none !important; }
  .about {
    padding-left: 5%;
    padding-right: 5%; }
  .homepageMenu ul li {
    margin: 10px;
    display: block; }
  .pageNavigation ul li {
    margin: 10px;
    display: block; }
  .didyouknow .arrowPrev, .didyouknow .arrowNext {
    display: none !important; }
  .didyouknow h2 {
    font-size: 34px;
    margin-bottom: 60px; }
  .didyouknow__Wrap {
    height: auto; }
  .didyouknow__Box {
    height: auto; }
    .didyouknow__Box--img {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      top: auto;
      transform: translateY(0); }
    .didyouknow__Box--content {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      top: auto;
      transform: translateY(0); }
  .video {
    background-size: auto 100%;
    background-position: center center !important;
    background-attachment: initial !important; }
    .video h2 {
      font-size: 34px; }
    .video h4 {
      font-size: 24px; }
  .latestNews h2 {
    font-size: 34px; }
  .latestNews h4 {
    font-size: 24px; }
  .latestNews__Box {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%; }
  .siteFooter__Widget {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center !important; }
  .postSingleGray__Top {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%; }
    .postSingleGray__Top h2 {
      font-size: 34px; }
    .postSingleGray__Top p {
      font-size: 16px; }
  .postSingleGray__Inner li {
    font-size: 16px; }
  .postSingleGray__Inner p {
    font-size: 16px; }
  .postSingleGray__locationMap {
    height: 450px; }
  .locations__Nav {
    height: auto;
    line-height: initial;
    padding: 30px 0; }
    .locations__Nav li {
      display: block;
      padding: 10px 0; }
      .locations__Nav li:after {
        display: none; }
  .locations__Box {
    height: auto; }
    .locations__Box--img {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      top: auto;
      transform: translateY(0);
      margin-bottom: 5%; }
    .locations__Box--content {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      top: auto;
      transform: translateY(0); }
  .postSingle__Top h2 {
    font-size: 34px;
    margin-left: 5%; }
  .postSingle__Inner {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
    .postSingle__Inner table {
      min-width: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
      margin-left: 0% !important;
      margin-right: 0% !important; }
  .blogPage__Top {
    padding-top: 30px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
    .blogPage__Top h2 {
      font-size: 34px; }
    .blogPage__Top h4 {
      margin-bottom: 0; }
  .blogPage__Box--cover {
    width: 100%;
    margin-bottom: 5%; }
  .blogPage__Content {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 0; }
  .stat {
    width: 100%;
    margin: 35px 0 !important; }
  .partsSingle__Top {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%; }
    .partsSingle__Top h2 {
      font-size: 34px; }
    .partsSingle__Top p {
      font-size: 16px; }
  .topBox__Description--title {
    padding: 10px;
    width: 100%;
    float: left; }
  .topBox__Description--image {
    padding: 10px;
    width: 90%;
    float: left;
    margin-left: 5%;
    margin-right: 5%; }
  .topBox__Description--content {
    padding: 10px;
    width: 100%;
    float: left;
    margin-left: 0; }
    .topBox__Description--content p {
      font-size: 14px; }
    .topBox__Description--content--more {
      float: none;
      margin: auto; }
  .topBox__Locations ul {
    column-count: 1;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  .topBox__locationMap {
    background-size: 100% auto;
    height: 300px; }
  .middleBox__Description--title {
    padding: 10px;
    width: 100%;
    float: left; }
  .middleBox__Description--image {
    padding: 10px;
    width: 90%;
    float: left;
    margin-left: 5%;
    margin-right: 5%; }
  .middleBox__Description--content {
    padding: 10px;
    width: 100%;
    float: left;
    margin-left: 0; }
    .middleBox__Description--content p {
      font-size: 14px; }
    .middleBox__Description--content--more {
      float: none;
      margin: auto; }
  .middleBox__Locations ul {
    column-count: 1;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  .bottomBox__Description--title {
    padding: 10px;
    width: 100%;
    float: left; }
  .bottomBox__Description--image {
    padding: 10px;
    width: 90%;
    float: left;
    margin-left: 5%;
    margin-right: 5%; }
  .bottomBox__Description--content {
    padding: 10px;
    width: 100%;
    float: left;
    margin-left: 0; }
    .bottomBox__Description--content p {
      font-size: 14px; }
    .bottomBox__Description--content--more {
      float: none;
      margin: auto; }
  .bottomBox__Locations ul {
    column-count: 1;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  .bottomBox__Cnt {
    padding: 10px;
    width: 100%;
    float: left;
    margin-left: 0; }
  .footerBox__Box {
    padding: 0 5%; }
    .footerBox__Box p {
      font-size: 14px; } }
