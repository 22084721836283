.nav { position: relative; font-size: 16px;float: left;text-align: center; width: 88%;left: 0;z-index: 10;
  ul { padding: 0; margin: 0 auto; list-style-type: none; text-align: left; display: block;height: 100px;line-height: 100px;float: right;}
  li { display: inline-block; text-align: center; vertical-align: middle; position: relative;
    ul {position: absolute;top: 88px;line-height: 1 !important;min-width: 300px;height: 100%;display: block;float: left;left: 20px;visibility: hidden;opacity: 0;transform:translateY(10px);transition:300ms;
      li {display: block;text-align: left;background: alpha($black,0.6);padding-left: 10px;
        &:first-of-type{padding-top: 10px;}
        &:last-of-type{padding-bottom: 10px;}
        a {line-height: 1.8 !important;padding: 0 !important;font-size: 13px;}
        ul {position: absolute;top: 0;left: 300px;visibility: hidden;opacity: 0;
          li {background: alpha($black,0.8);
            a {text-transform: initial;}
          }
        }
      }
    }

      &:hover {
        ul {transform:translateY(0);opacity: 1;visibility: visible;}
      }
      ul {
        li {
          ul {visibility: hidden !important;opacity: 0 !important;display: block !important;}
      &:hover {
        ul {transform:translateY(0);opacity: 1 !important;visibility: visible !important;}
      }
        }
      }
  }
  a { color: $white;  font-size: 16px; text-decoration: none; line-height: 80px; margin: 0; display: block; position: relative;transition: 300ms;padding: 0 20px;font-weight: lighter;z-index: 1;text-transform: uppercase;letter-spacing: 1.5px;
    // &:before {content: '';position: absolute;left: 15px;right: 15px;bottom: 30px;height: 1px;background: $white;opacity: 0;transition:300ms;}
    &:hover {
      color: $green;
      // &:before {opacity: 1;}
    }
  }
  li.current_page_item {
    a {
      color: $green;
      // &:before {content: '';position: absolute;left: 15px;right: 15px;bottom: 30px;height: 1px;background: $white;opacity: 1;transition:300ms;}
    }
    ul {
      li {
        a {color: $white !important;
          &:hover {color: $green !important;}
        }
        ul {
          li {
            a {color: $white !important;
          &:hover {color: $green !important;}
            }
          }
        }
      }
    }
  } 
}
