.homeSlider {margin-bottom: 0 !important;float: left;width: 100%;overflow: hidden;
    &__Slide {height: 100vh !important;float: left;width: 100%;position: relative;background-size: cover;background-repeat: no-repeat;width: 100%;z-index: 1;background-attachment: fixed !important;
        &:before {content: '';z-index: -1;position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.3;}
        .center {height: 100vh;}
    }
    h2 {font-size: 50px;color: $white;margin: 0px;position: relative;z-index: 1;font-weight: bold;text-transform: uppercase}
    h4 {font-size: 34px;font-weight: 300;color: $white;margin-top: 0;margin-bottom: 20px;font-family: $second;font-weight: 100;}
    img {max-width: 200px;margin-bottom: 40px;}
    &__More {background: $brand2;color: $brand; text-transform: uppercase;padding-left: 40px;padding-right: 40px;height: 40px;line-height: 40px;font-weight: normal;border: 0;display: inline-block;text-decoration: none;transition:300ms;
        &:hover {
            color: $white;background: $brand;
        }
    }
    &__Inner {position: absolute;}
.topLeft {top: 150px;left: 50px;text-align: left;}
.topCenter {top: 150px;left: 0;right: 0;text-align: center;}
.topRight {top: 150px;right: 50px;text-align: right;}
.centerLeft {top: 50%;transform:translateY(-50%);left: 50px;text-align: left;}
.centerCenter {top: 50%;transform:translateY(-50%);left: 0;right: 0;text-align: center;}
.centerRight{top: 50%;transform:translateY(-50%);right: 50px;text-align: right;}
.bottomLeft {bottom:150px;left: 50px;text-align: left;}
.bottomCenter {bottom:150px;left: 0;right: 0;text-align: center;}
.bottomRight {bottom:150px;right: 50px;text-align: right;}
    &__Show {margin-top: 200px;color: $white;float: left;width: 100%;
            i {transform:rotate(90deg);}
    }

.slick-dots { bottom: 50px; height: 15px;line-height: 15px;margin: 0;
    li {width: 15px;height: 15px;margin: 0 2.5px;
      button {padding: 0 !important;width: 15px;height: 15px;line-height: 15px;position: relative;z-index: 1;
        &:before { font-size: 35px; color: $white; opacity: 0.5;width: 15px;border: 1px solid $white;height: 15px;line-height: 15px;padding-left: 1px;border-radius: 100%;content: '';}
        &:after { opacity: 0.5;border-radius: 100%;content: '';background: $white;z-index: -1;position: absolute;left: 2px;top: 2px;right: 2px;bottom: 2px;}
      }
    }
    li.slick-active {
      button {
        &:before { color: $white; opacity: 1; font-size: 35px;}
      }
    }
  }
.arrowPrev, .arrowNext {display: block;position: absolute;top: 0;bottom: 0;width: 60px;height: 100%;z-index: 10000;}
.arrowPrev {height: 100vh;line-height: 100vh; opacity:1; transition:200ms;color: $white;font-size: 40px;padding-left: 15px;
  &:after {width: 70px;height: 100px;bottom: 45%;top: 45%;left: 0px;position: absolute;content: '';background: alpha($white,0.3);text-align: center;transition:200ms;}
  &:hover {transition:200ms;
    &:before {opacity: 0.6;transition:200ms;}
  }
  &:before {line-height: 100vh;opacity: 1;font-size: 60px !important;z-index: 10000;color: $white;position: absolute;left: 20px;transition:200ms;}
}

.arrowNext {height: 100vh;line-height: 100vh;transition:200ms;color: $white;font-size: 40px; opacity:1; transition:200ms;right: 0;text-align: right;padding-right: 15px;
  &:after {width: 70px;height: 100px;bottom: 45%;top: 45%;right: 0px;position: absolute;content: '';background: alpha($white,0.3);text-align: center;transition:200ms;}
  &:hover {transition:200ms;
    &:before {opacity: 0.6;transition:200ms;}
  }
  &:before {line-height: 100vh;opacity: 1;font-size: 60px !important;z-index: 10000;color: $white;position: absolute;right: 20px;transition:200ms;}
}
}
.threewidgets {
    float: left;width: 100%;background: $brand;
    &__Box {
        float: left;width: 32.33%;margin-left: 0.5%;margin-right: 0.5%;text-align: center;padding: 50px 0;
        i {color: $white;font-size: 30px;border: 1px solid $white;border-radius: 100%;height: 60px;width: 60px;text-align: center;line-height: 57px;}
        h3 {color: $white;}
        p {color: $white; padding-left: 20%;margin-right: 20%;}
        a {font-size: 14px;text-decoration: none;text-transform: uppercase;color: $white;z-index: 1;position: relative;
            &:before {content: '';position: absolute;bottom: -10px;left: 0;right: 0;height: 1px;background: $white;z-index: -1;opacity: 0;transition:300ms;}
            &:hover {
                &:before {bottom: -2px;opacity: 1;}
            }
        }
        &:nth-of-type(2) {background: alpha($white,0.1);}
    }
}
.sixbox {
    float: left;width: 100%;background-size: cover;background-repeat: no-repeat;padding: 50px 0;position: relative;z-index: 1;background-attachment: fixed;
    &:before {content: '';position: absolute;z-index: -1;top: 0;right: 0;bottom: 0;left: 0;background: $white;opacity: 0.5;}
    &__Box {
        float: left;width: 24%;margin-left: 0.5%;margin-right: 0.5%;text-align: center;height: 200px;background-size: cover;background-repeat: no-repeat;background-position: center;margin-bottom: 20px;position: relative;overflow: hidden;
        h3 {color: $brand;position: absolute;top: 134px;left: 0;right: 0;padding: 10px 0;z-index: 1;font-weight: normal;transition:300ms;
            &:before {position: absolute;content: '';top: 0;right: 0;bottom: 0;left: 0;background: $white;z-index: -1;opacity: 0.7;}
        }
        p {color: $brand; padding-left: 20%;margin-right: 20%;position: absolute;top: 250px;transition:500ms;z-index: 1;}
        a {text-decoration: none;}
        &:nth-of-type(1) {clear: both;}
        &:nth-of-type(2) {clear: both;}
        &:nth-of-type(3) {height: 420px;margin-top: -220px;
            h3 {top: 354px;}
            p {top: 500px;}
            &:hover {
                h3 {top: -18px;bottom: -19px;padding-top: 80px;}
                p {top: 100px;}
            }
        }
        &:nth-of-type(4) {height: 420px;margin-top: -220px;
            h3 {top: 354px;}
            p {top: 500px;}
            &:hover {
                h3 {top: -18px;bottom: -19px;padding-top: 80px;}
                p {top: 100px;}
            }
        }
        &:nth-of-type(5) {margin-top: -220px;}
        &:hover {
            h3 {top: -18px;bottom: -19px;padding-top: 40px;}
            p {top: 60px;}
        }
    }
}
.about {
    float: left;width: 100%;text-align: center;background: $white;padding: 60px 0;
    h2 {font-size: 40px;color: $black;margin: 0px;position: relative;z-index: 1;font-weight: bold;text-transform: uppercase}
    p {font-weight: 300;color: $brand;}
    img {max-width: 300px;margin-bottom: 40px;opacity: 0.7;}
    .center {max-width: 740px;}
}
.numbers {float: left;width: 100%;padding: 0;
    h3 {display: none;}
    .statistics-inner {padding: 0 !important;}
    .stat .fa {display: none !important;}
}
.homepageMenu {
    float: left;width: 100%;background: $green;text-align: center;
    ul {padding: 0;margin: 0;
        li {list-style: none;display: inline-block;padding: 30px 0;
            a {text-decoration: none;color: $white;text-transform: uppercase;transition:300ms;padding: 30px 10px;
                &:hover {background: $darkgreen;}
            }
        }
    }
}
.didyouknow {
    float: left;width: 100%;padding: 60px 0;
    h2 {text-align: center;font-weight: lighter;font-size: 60px;margin: 0;}
    .arrowPrev, .arrowNext {display: block;position: absolute;top: 0;bottom: 0;width: 60px;height: 100%;z-index: 10000;}
.arrowPrev {height: 300px;line-height: 300px; opacity:1; transition:200ms;color: $white;font-size: 40px;padding-left: 15px;
  &:after {width: 70px;height: 100px;bottom: 45%;top: 45%;left: 0px;position: absolute;content: '';background: transparent;text-align: center;transition:200ms;}
  &:hover {transition:200ms;
    &:before {opacity: 0.6;transition:200ms;}
  }
  &:before {line-height: 300px;opacity: 1;font-size: 60px !important;z-index: 10000;color: $green;position: absolute;left: -120px;transition:200ms;}
}

.arrowNext {height: 300px;line-height: 300px;transition:200ms;color: $white;font-size: 40px; opacity:1; transition:200ms;right: 0;text-align: right;padding-right: 15px;
  &:after {width: 70px;height: 100px;bottom: 45%;top: 45%;right: 0px;position: absolute;content: '';background: transparent;text-align: center;transition:200ms;}
  &:hover {transition:200ms;
    &:before {opacity: 0.6;transition:200ms;}
  }
  &:before {line-height: 300px;opacity: 1;font-size: 60px !important;z-index: 10000;color: $green;position: absolute;right: -120px;transition:200ms;}
}
&__Wrap {
    height: 300px;margin: auto;width: 100%;max-width: 860px;
}
&__Box {
    float: left;width: 100%;position: relative;height: 300px;
    a {text-decoration: none;}
    &--img {float: left;width: 30%;height: 200px;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;top: 50%;transform:translateY(-50%);}
    &--content {width: 65%;margin-left: 5%;float: left;position: relative;top: 40%;transform:translateY(-50%);
        h4 {font-size: 28px;color: $green;font-weight: lighter;margin: 0;
            i {font-size: 20px;}
        }
        p {color: $brand;}
        &--more{background: $brand2;color: $brand; text-transform: uppercase;padding-left: 40px;padding-right: 40px;height: 40px;line-height: 40px;font-weight: normal;border: 0;display: inline-block;text-decoration: none;transition:300ms;
        &:hover {
            color: $white;background: $brand;
        }
    }
    }
}
}
.video {
    float: left;width: 100%;height: 600px;text-align: center;background-size: cover;background-repeat: no-repeat;position: relative;z-index: 1;background-attachment: fixed;
    &:before {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.2;z-index: -1;}
    &__Cnt {position: relative;top: 50%;transform:translateY(-50%);}
    h2 {font-size: 60px;color: $white;margin-bottom: 0;}
    h4 {font-size: 48px;color: $white;margin-top: 0;font-family: $second;font-weight: 100;}
    a {text-decoration: none;color: $white;
        i {display: block;height: 70px;width: 70px;background: alpha($white,0.4);border-radius: 100%;margin: auto;font-size: 30px;line-height: 70px;text-align: center;padding-left: 5px;margin-bottom: 15px;
        }
    }
}
.latestNews {
    float: left;width: 100%;padding: 60px 0;background: $white;
    h2 {text-align: center;font-weight: lighter;font-size: 60px;margin: 0;}
    h4 {text-align: center;font-weight: lighter;font-size: 30px;margin: 0;margin-bottom: 60px;}
&__Wrap {
 width: 100%;max-width: 860px;margin: auto;
}
&__Box {
    float: left;width: 32.33%;position: relative;min-height: 300px;margin-left: 0.5%;margin-right: 0.5%;text-align: center;border: 1px solid #ededed;transition:300ms;
    a {text-decoration: none;}
    &--img {float: left;width: 100%;height: 150px;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;
    }
    &--author {float: left;width: 50%;height: 30px;background: $green;line-height: 30px;margin-bottom: 30px;
        span {color: $white;font-size: 14px;}
    }
    &--date {float: left;width: 50%;height: 30px;background: $green;position: relative;z-index: 1;line-height: 30px;margin-bottom: 30px;
        span {color: $white;font-size: 14px;}
        &:before {position: absolute;content: '';top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.2;z-index: -1;}
    }
    &--content {width: 80%;float: left;position: relative;margin-left: 10%;margin-right: 10%;
        h4 {font-size: 18px;color: $brand;font-weight: lighter;margin: 0;}
        p {color: $brand;font-size: 14px;}
    }
    &:hover {
        border-color: $green;
    }
}
}
.parallax {
  background-position: 0 0;
}
.addPosition {position: relative;}
.customLink {
    position: absolute;top: 40px;right: 0px;font-size: 14px;color: $brand;
    a {color: $brand;}
}
.leftLink {right: auto;left: 0;}
@media screen and (max-width: 800px) { 
    .customLink {
    top: -20px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
}
.leftLink {
    top: -20px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
}
}