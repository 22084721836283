.blogPage {padding-bottom: 250px;float: left;width: 100%;background: $white;
  &__Top {width: 100%;max-width: 800px;margin: auto;padding-top: 200px;text-align: center;
      h2 {margin: 0;font-size: 48px;color: $brand;font-weight: lighter;line-height: 1.2;max-width: 800px;margin: auto;margin-bottom: 30px;
        a {text-decoration: none;color: $brand;}
      }
      h3 {margin: 0;color: $brand;font-size: 16px;max-width: 400px;margin: auto;margin-bottom: 70px;font-weight: lighter;line-height: 1.3;}
      h4 {margin: 0;color: $brand;font-size: 12px;text-transform: uppercase;letter-spacing: 2px;max-width: 500px;margin: auto;margin-bottom: 100px;font-weight: lighter;}
      h5 {margin: 0;color: $brand;font-size: 16px;display: inline-block;margin-bottom: 70px;font-weight: lighter;line-height: 1.3;text-transform: uppercase;margin: 0 5px;
        &:hover {color: $black;cursor: pointer;}
      }
      .activeProducts {color: $black;}
      .smallCategory {text-decoration: none;margin: 0 5px;}
  }
  &__Bottom {width: 100%;max-width: 800px;margin: auto;padding-top: 130px;text-align: center;
      h2 {margin: 0;font-size: 36px;color: $white;font-weight: lighter;line-height: 1.2;max-width: 800px;margin: auto;margin-bottom: 10px;}
      h4 {margin: 0;color: $brand;font-size: 12px;text-transform: uppercase;letter-spacing: 6px;max-width: 400px;margin: auto;margin-bottom: 20px;font-weight: lighter;}
      p {font-size: 18px;line-height: 1.3;text-align: center;color: $white;font-weight: lighter;margin: 0;margin-bottom: 50px;}
  }
  &__More {@include moreButton($brand, $brand); color: $white; border-radius: 6px;
        &:hover {background: #07b661; color: $white; border-color: #07b661;}
    }
  &__Content {
    background: $white;padding: 100px;padding-top: 0;
    
  }
  &__Inner {max-width: 600px;margin: auto;}
  &__Box {padding: 50px 0px;background: $white;position: relative;z-index: 1;float: left;width: 100%;
        h2 {font-weight: normal;margin: 0;color: $black;font-size: 28px;line-height: 1.3;margin-bottom: 20px;transition:300ms;
          &:hover {color: $brand;}
        }
        h4 {text-transform: uppercase;font-size: 12px;line-height: 2;color: $brand;font-weight: bold;margin: 0;margin-bottom: 10px;letter-spacing: 1.1px;
        ul {padding: 0;margin: 0;
          li {list-style: none;display: inline-block;position: relative;z-index: 1;margin-right: 10px;
            &:before {content: '';left: -7px;top: 5px;bottom: 5px;width: 1px;background: #f0f0f0;z-index: -1;position: absolute;}
            &:first-of-type {
              &:before {display: none;}
            }
            a {text-decoration: none;color: $brand;}
          }
        }
        }
        &--cover {float: left;width: 100%;height: 300px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
        &--left {float: left;width: 47%;margin-right: 3%;}
        &--right {float: left;width: 50%;}
        p {font-size: 18px;color: $black;font-weight: lighter;margin: 0;line-height: 1.6;}
        &:before {left: 0px;right: 0;bottom: 0;height: 1px;background: #f0f0f0;position: absolute;content: '';z-index: -1;}
        a {text-decoration: none;transition:300ms;}
        &:last-of-type {
          &:before {display: none;}
        }
        .postMore {@include moreButton($brand, $brand);color: $white;margin-top: 50px;
          &:hover {color: $brand;}
        }
  }
  &__Pagination {float: left;width: 100%;text-align: center;position: absolute;bottom: -50px;left: 0;
    img {margin-bottom: 10px;}
    h4 {font-weight: lighter;color: $brand;text-transform: uppercase;letter-spacing: 1.7px;font-size: 12px;margin: 0;}
    a {text-decoration: none;}
    &--plus {width: 40px;height: 40px;border-radius: 100%;line-height: 40px;text-align: center;position: relative;z-index: 1;margin: auto;margin-bottom: 10px;background: $brand;
      &:before {content: '+';position: absolute;left: 0;right: 0;bottom: 0;top: 0;color: $white;font-size: 20px;z-index: -1;}
    }
  }
  .bottomPosts {
    .blog__Box {margin-bottom: 0 !important;margin-top: 0 !important;
      &:before {display: block;}
    }
  }
 
 
}