.siteFooter {float: left;width: 100%;background: $brand;padding: 60px 0;
  &__Wrap {
 width: 100%;max-width: 860px;margin: auto;
}
  &__Widget {width: 30%;
    &:nth-of-type(1) {float: left;}
    &:nth-of-type(3) {float: right;}
    &:nth-of-type(2) {margin-left: 5%;float: left;margin-right: 5%;}
    h2 {font-size: 18px;color: $white;font-weight: lighter;}
    p {font-size: 14px;color: $white;font-weight: lighter;}
    a {font-size: 14px;color: $white;text-decoration: none;font-weight: lighter;}
    i {margin-right: 10px;width: 20px;}
    img {max-width: 150px;}
    li {list-style: none;display: block;}
    ul {padding: 0;margin: 0;}
  }
}