#lightboxOverlay {
    z-index: 100000000;
}
#lightbox {
    z-index: 100000000;
}
.pageSingle {float: left;width: 100%;background: $white;
  &__Content {overflow: hidden;position: relative;padding-bottom: 100px;
    
  }
  &__Top {width: 100%;max-width: 800px;margin: auto;text-align: center;margin-top: 70px;
      h2 {margin: 0;font-size: 48px;color: $brand;font-weight: lighter;line-height: 1.2;max-width: 800px;margin: auto;margin-bottom: 120px;position: relative;z-index: 1;
        &:before {content: '';position: absolute;bottom: -50px;left: 0;right: 0;background: #f0f0f0;height: 1px;}
      }
      h5 {color: $black;font-weight: bold;}
      a {text-decoration: none;font-weight: normal;color: $black;}
  }
  &__Cover {float: left;width: 100%;height: 450px;background-repeat: no-repeat;background-size: auto 100%;background-position: center;margin-bottom: 50px;}
  &__Map {float: left;width: 100%;height: 400px;margin-bottom: 50px;z-index: 10000000000000000;
    img {max-width: inherit !important;}
  }

  &__Inner {max-width: 665px;margin: auto;
    h1 {font-size: 48px;line-height: 1.2;font-weight: lighter;}
    h2 {font-size: 26px;line-height: 1.4;font-weight: lighter;}
    h3 {font-size: 22px;font-weight: bold;}
    h4, h5, h6 {font-size: 18px; line-height: 1.6;color: $black;}
    .wp-caption {width: auto !important;
      p {font-size: 12px;font-weight: lighter;color: $brand;}
    }
    p {font-size: 18px; line-height: 1.6;color: $black;}
    li {font-size: 18px; line-height: 1.6;color: $black;}
    span {font-size: 18px; line-height: 1.6;color: $black;}
    a {font-size: 18px; line-height: 1.6;color: $brand;text-decoration: none;border-bottom: 1px dashed $brand;
      &:hover {border-bottom: 1px solid $brand;}
    }
    mark {background: #e7f5fe;}
    blockquote {background: #e7f5fe; font-weight: lighter; font-size: 16px; padding: 25px;color: #004e99;width: 100%;float: left;margin-left: 0;margin-right: 0;line-height: 1.8;margin-bottom: 60px;
      p {margin: 0;line-height: 1.8;color: #004e99;}
    }
    &--intro {margin-top: 60px;margin-bottom: 60px;
      p {font-size: 26px;line-height: 1.4;font-weight: lighter;}
      a {color: $brand;border-bottom: 1px dotted $brand;text-decoration: none;font-size: 26px;line-height: 1.4;font-weight: lighter;
        &:hover {border-bottom: 1px solid $brand;}
      }
    }
    input {width: 100%;border: 1px solid #dedede;padding: 0 10px;color: $black;font-weight: lighter;line-height: 46px;font-size: 14px;}
    textarea {width: 100%;max-width: 100%;min-width: 100%;border: 1px solid #dedede;padding: 10px; color: $black;font-weight: lighter;font-size: 14px;}
    input[type="submit"] {@include moreButton($brand, $brand);color: $white;font-weight: 300;
      &:hover {border-color: $brand;color: $brand;background: $white;}
    }
  }
}
.pageSingle__Inner--box {float: left;width: 46%;position: relative;z-index: 1;margin-left: 2%;margin-right: 2%;
  &:before {content: '';position: absolute;left: 0;bottom: 0;right: 0px;background: $gray;height: 1px;z-index: -1;}
  p {font-size: 14px;}
  &:last-of-type {
    &:before {display: none !important;}
  }
  &:nth-last-of-type(2){
    &:before {display: none !important;}
  }
}
.full-width {width: 96% !important;
    &:before {display: none !important;}
    &:first-of-type {
      &:before {display: block !important;}
    }
}
.fullWidth {width: 100%;}
.pageNavigation {
    float: left;width: 100%;background: $green;text-align: center;margin-top: 50px;position: relative;z-index: 8000;
    ul {padding: 0;margin: 0;
        li {list-style: none;display: inline-block;padding: 30px 0;
            a {text-decoration: none;color: $white;text-transform: uppercase;transition:300ms;padding: 30px 10px;
                &:hover {background: $darkgreen;}
            }
        }
    }
}
.colorYellow {
  background: $brand2 !important;
  a {background-color: transparent !important;
      &:hover {color: $brand !important;}
  }
}
.fullPageNavigation {
   float: left;width: 100%;background: $gray;text-align: left;margin-top: 50px;position: relative;z-index: 8000;padding: 20px 0;
   &__Inner {max-width: 665px;margin: auto;}
   ul {padding: 0;margin: 0;
        li {padding: 10px 0;
            a {text-decoration: none;color: $black;text-transform: uppercase;transition:300ms;padding: 30px 10px;
                &:hover {color: $green;}
            }
        }
    }
}
.cntBox {float: left;width: 100%;padding: 100px 0 0 0;
  &__Title {
    float: left;min-width: 110% !important;max-width: 110% !important;width: 110% !important;margin-left: -5% !important;margin-right: -5% !important;padding: 10px 5%;background: $green;color: $white;font-size: 16px;font-weight: bold;margin-bottom: 20px;text-transform: uppercase;
  }
}

.blockBox {float: left;width: 100%;margin-top: 90px;margin-bottom: -70px;
  &__Inner {max-width: 665px;margin: auto;}
  &__Title {
    float: left;min-width: 110% !important;max-width: 110% !important;width: 110% !important;margin-left: -5% !important;margin-right: -5% !important;padding: 10px 5%;background: $green;color: $white;font-size: 16px;font-weight: bold;text-transform: uppercase;
  }
  p {font-size: 18px; line-height: 1.6;color: $black;margin-bottom: 50px;}
  &__Wraper {float: left;margin-top: 30px;margin-bottom: 20px;}
}