// COLORS
  $brand: #3f3222;
  $brand2: #ebb33a;
  $brand3: #f2f2f2;
  
  $red: #AD0A0A;
  $white: #FFF;
  $gray: #e2e2e2; 
  $green: #5ec26a; 
  $darkgreen: #3E7F46;
  $black: #000;
  $black-two: #1f1f1f;
  $gray-light: #999;
  $social: #6d6b64;
  $facebook: #3B5997;
  $twitter: #4099FF;
  
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&subset=cyrillic-ext');
@import url('https://fonts.googleapis.com/css?family=Palanquin:100,200,300,400,500,600,700&subset=latin-ext');

  $font-size: 16px;
  $line-height: 1.5;
  $default: 'Open Sans', sans-serif;
  $second: 'Palanquin', sans-serif;
  // $light: 100;
  $regular: 400;
  $mediumbold: 500;
  $semibold: 600;
  $bold: 700;
  $ultra: 900;

  $xxsmall: em(10px);
  $xsmall: em(12px);
  $small: em(14px);
  $medium: em(16px);
  $large: em(18px);
  $xlarge: em(20px);
  $xxlarge: em(24px);
  
// DEFAULTS
  body { font: #{$font-size}/#{$line-height} $default; height: 100%;background-color: $gray;}
  

  @function alpha($color: $black, $opacity: 0.5) { 
    $alpha-color-value: rgba($color, $opacity); 
    @return $alpha-color-value;
  }