.mobile {display: none !important;}
@media screen and (max-width: 800px) { 
.productForm {width: 100%;padding: 10px;top: -300px;
    &__Close {top: 10px;right: 10px;}
}
.mobile {display: inline-block !important;}
.site {padding-top: 0;}
.siteHeader {height: auto;position: relative;margin-bottom: 0;top: 0 !important;transform:translateY(0) !important;
  &__Top {height: auto;line-height: 1;}
  &__Left {display: none;}
  &__Right {display: none;}
  &__Logo {width: 100%; padding-top: 15px;padding-bottom: 15px;text-align: right;padding-right: 15px;line-height:initial;
    img {max-width: 90px;}
  }
}
.nav {position: absolute;background: $brand;transform:translateX(-100%);z-index: 1000000000;transition:500ms !important;width: 100%;
  ul {height: 100vh;line-height: 1;display: block;background: $brand;float: left;padding-top: 30px;}
  li {display: table;float: left;width: 100%;margin: 0;padding: 0;text-align: left;padding-left: 0px;background: $brand;
    ul {position: relative;opacity: 1;visibility: visible;transform: translateY(00px);top: 0;left: 0;padding-top: 0;
      li {background-color: transparent;padding-left: 30px;
        a {}
        ul {position: relative;visibility: visible !important;opacity: 1 !important;left: 0;top: 0;padding-top: 0;
          li {background-color: transparent;}
        }
      }
    }
  }
  a {padding: 0 20px;line-height: 3;}
}
.callNav {position: absolute;right: -55px;top: 20px;padding: 10px 15px;background: transparent;cursor: pointer;z-index: 10000000000000;
  i {color: $brand2;transition:700ms;font-size: 30px;}
}
.activeNav {transform:translateX(0%);opacity: 1;transition:500ms !important;
  .callNav {right: 10px;
    i {color: $brand2;transform:rotate(180deg);
      &:before {content: "\f00d" !important;}
    }
  }
}
.homeSlider {
  h2 {font-size: 34px;}
  h4 {font-size: 24px;}
  &__Slide {background-size: auto 100%; background-position: center center !important; background-attachment: initial !important;}
}
.topLeft {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.topCenter {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.topRight {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.centerLeft {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.centerCenter {top: 50%;transform:translateY(-50%);left: 0;right: 0;text-align: center;}
.centerRight{top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.bottomLeft {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.bottomCenter {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.bottomRight {top: 50% !important;transform:translateY(-50%) !important;left: 0 !important;right: 0 !important;text-align: center !important;}
.threewidgets {
  &__Box {
    width: 100%;margin: 0;
  }
}
.sixbox {background-size: auto 100%; background-position: center center !important; background-attachment: initial !important;
  &__Box {width: 90% !important;margin-left: 5% !important;margin-right: 5% !important;margin-top: 0 !important;margin-bottom: 5% !important; height: 200px !important;

h3 {color: $brand !important;position: absolute !important;top: 134px !important;left: 0 !important;right: 0 !important;padding: 10px 0 !important;z-index: 1 !important;font-weight: normal !important;transition:300ms !important;
            &:before {position: absolute !important;content: '' !important;top: 0 !important;right: 0 !important;bottom: 0 !important;left: 0 !important;background: $white !important;z-index: -1 !important;opacity: 0.7 !important;}
        }
        p {display: none !important;}
  }
}
.about {
  padding-left: 5%;padding-right: 5%;
}
.homepageMenu {
  ul {
    li {margin: 10px;display: block;}
  }
}
.pageNavigation {
  ul {
    li {margin: 10px;display: block;}
  }
}
.didyouknow {
  .arrowPrev, .arrowNext {display: none !important;}
  h2 {font-size: 34px;margin-bottom: 60px;}
  &__Wrap {height: auto;}
  &__Box {height: auto;
    &--img {width: 90%;margin-left: 5%;margin-right: 5%; top: auto;transform:translateY(0);}
    &--content {width: 90%;margin-left: 5%;margin-right: 5%; top: auto;transform:translateY(0);}
  }
}
.video {background-size: auto 100%; background-position: center center !important; background-attachment: initial !important;
  h2 {font-size: 34px;}
  h4 {font-size: 24px;}
}
.latestNews {
  h2 {font-size: 34px;}
  h4 {font-size: 24px;}
  &__Box {width: 90%;margin-left: 5%;margin-right: 5%;margin-bottom: 5%;}
}
.siteFooter {
  &__Widget {width: 90%;margin-left: 5%;margin-right: 5%;text-align: center !important;}
}
.postSingleGray {
  &__Top {width: 100%;padding-left: 5%;padding-right: 5%;
    h2 {font-size: 34px;}
    p {font-size: 16px;}
  }
  &__Inner {
    li {font-size: 16px;}
    p {font-size: 16px;}
  }
  &__locationMap {
    height: 450px;
  }
}
.locations {
  &__Nav {
    height: auto;line-height: initial;padding: 30px 0;
    li {display: block;padding: 10px 0;
      &:after {display: none;}
    }
  }
  &__Box {height: auto;
    &--img {width: 90%;margin-left: 5%;margin-right: 5%; top: auto;transform:translateY(0);margin-bottom: 5%;}
    &--content {width: 90%;margin-left: 5%;margin-right: 5%; top: auto;transform:translateY(0);}
  }
}
.postSingle {
  &__Top {
    h2 {font-size: 34px;margin-left: 5%;}
  }
  &__Inner {width: 90%;margin-left: 5%;margin-right: 5%;
    table {min-width: 100% !important;
max-width: 100% !important;
width: 100% !important;
margin-left: 0% !important;
margin-right: 0% !important;}
  }
}
.blogPage {
  &__Top {padding-top: 30px;width: 90%;margin-left: 5%;margin-right: 5%;
    h2 {font-size: 34px;}
    h4 {margin-bottom: 0;}
  }
  &__Box {
    &--cover {width: 100%;margin-bottom: 5%;}
  }
  &__Content {width: 90%;margin-left: 5%;margin-right: 5%;padding: 0;}
}
.stat {
    width: 100%;
    margin: 35px 0 !important;
}
.partsSingle {
   &__Top {width: 100%;padding-left: 5%;padding-right: 5%;
    h2 {font-size: 34px;}
    p {font-size: 16px;}
  }
}
.topBox {
  &__Description {
    &--title {padding:10px;width: 100%;float: left;}
    &--image {padding:10px;width: 90%;float: left;margin-left: 5%;margin-right: 5%;}
    &--content {padding:10px;width: 100%;float: left;margin-left: 0;
      p {font-size: 14px;}
      &--more {float: none;margin: auto;}
    }
  }
  &__Locations {
    ul {column-count: 1;width: 90%;margin-left: 5%;margin-right: 5%;}
  }
  &__locationMap {background-size:100% auto; height: 300px;}
}
.middleBox {
  &__Description {
    &--title {padding:10px;width: 100%;float: left;}
    &--image {padding:10px;width: 90%;float: left;margin-left: 5%;margin-right: 5%;}
    &--content {padding:10px;width: 100%;float: left;margin-left: 0;
      p {font-size: 14px;}
      &--more {float: none;margin: auto;}
    }
  }
  &__Locations {
    ul {column-count: 1;width: 90%;margin-left: 5%;margin-right: 5%;}
  }
}
.bottomBox {
  &__Description {
    &--title {padding:10px;width: 100%;float: left;}
    &--image {padding:10px;width: 90%;float: left;margin-left: 5%;margin-right: 5%;}
    &--content {padding:10px;width: 100%;float: left;margin-left: 0;
      p {font-size: 14px;}
      &--more {float: none;margin: auto;}
    }
  }
  &__Locations {
    ul {column-count: 1;width: 90%;margin-left: 5%;margin-right: 5%;}
  }
  &__Cnt {
    padding:10px;width: 100%;float: left;margin-left: 0;
  }
}
.footerBox {
  &__Box {padding: 0 5%;
    p {font-size: 14px;}
  }
}
}