// BOWER
@import "../../assets/bower_components/normalize.css/normalize.css";
@import "../../assets/bower_components/slick-carousel/slick/slick.scss";
@import "../../assets/bower_components/slick-carousel/slick/slick-theme.scss";

// BOURBON/NEAT
@import "bourbon";
@import "neat";

// CORE
@import "core/grid-settings";
@import "core/defaults";
@import "core/mixins";


// MODULES
// @import "modules/sprite";
@import "modules/template"; // Main template file
@import "modules/home"; // Main template file
@import "modules/blog";
@import "modules/default";
@import "modules/post";
@import "modules/product";

// PARTIALS
@import "partials/header";
@import "partials/footer";
@import "partials/nav";

// MOBILE
@import "modules/mobile";